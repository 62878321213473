import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import axios from "axios";
import { io } from "socket.io-client";
import TextField from "@mui/material/TextField";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';

import logo from "../images/logo.svg";
import signupCover from "../images/signupCover.png";
import signElement from "../images/signElement.png";
import api from "../api";
import { Loader } from "./loader";
import userAction from "../redux/users/action";
import socket from "../helpers/socket";
import { useAlert } from "react-alert";

export function SignIn(params) {
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const alert = useAlert();

  const { setUser } = userAction;
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  
  useEffect(() => {
    if (userStore?.users?.token) {
        if (userStore.users.user.profileType == "admin") {
          navigate("/payment-admin");
        }
        else {
          navigate("/payment");
        }
    }
  }, []);

  const login = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const validatorsResponse = await validators();

      if (validatorsResponse) {
        throw new Error(validatorsResponse);
      }

      const payload = {
        email: data?.email,
        password: data?.password,
      };
      const registerationResponse = await api.login(payload);

      axios.defaults.headers.common["Authorization"] =
        registerationResponse.token;

      const userId = registerationResponse?.user?._id;

      socket.emit("joinRoom", { userId });
      localStorage.setItem("token", registerationResponse.token);
      localStorage.setItem("_id", userId);

      dispatch(setUser(registerationResponse));

      if (registerationResponse?.log?.is2FAVerified && !registerationResponse?.user?.emailVerified) {
        console.log('inn')
        const sendVerifyEmail = await api.sendEmail({
          _id: registerationResponse?.user?._id,
        });
      }

      navigate("/twofa-verify");
      setLoader(false);
    } catch (e) {
      alert.show(e.message);
      // alert.show("Invalid Credentials or Account not verified");
      setLoader(false);
    }
  };

  const validators = () => {
    if (!data.email) {
      return "Email is required";
    }
    if (!data.password) {
      return "Password is required";
    }
    return false;
  };

  const onTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div className="signupPage">
      <img className="element" src={signElement} alt="signElement" />
      <div className="logoDiv">
        <img src={logo} alt="logo" />
      </div>
      <div className="sigupRow">
        <div className="container">
          <div className="row jc-sb ai-center">
            <div className="col-lg-6">
              <img
                className="img-fluid signupCover"
                src={signupCover}
                alt="cover"
              />
            </div>
            <div className="col-lg-5">
              <div className="signForm">
                <h2>Welcome To,</h2>
                <h2 className="mb-4">Wealth Coach Waqar</h2>
                <form>
                  <div className="mb-3">
                    <TextField
                      onChange={onTextChange}
                      name="email"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                    />
                  </div>
                  {/* <div className="mb-3">
                    <TextField
                      onChange={onTextChange}
                      name="password"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="Password"
                      variant="outlined"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </div> */}
                  <FormControl  variant="outlined" className="w-100 mb-3">
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                      onChange={onTextChange}
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  <div className="d-flex jc-sb ai-center my-4">
                    {/* <div className="form-check">
                                        <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Keep me logged in</label>
                                    </div> */}
                    <div onClick={()=> navigate('/forget-password')} className="forget">Forgot Password?</div>
                  </div>
                  <button type="submit" onClick={login} className="btn signBtn">
                    Sign In
                  </button>

                  <div className="alreadySign">
                    <p>
                      Don't have an Account? <Link to="/signup">Sign-Up</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Loader loader={loader} />
    </div>
  );
}
