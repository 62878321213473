import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { Loader } from "./loader";
import { Modal } from "./modal";
import Edit from "../images/write.png";
import Eye from "../images/eye.png";
import Cross from "../images/cross-sign.png";
import Dollar from "../images/dollar.png";

export function Payment_Admin(props) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [payModal, setPayModal] = useState(false);
  const [editpayModal, setEditPayModal] = useState(false);
  const [editpayModal_byUser, setEditPayModal_byUser] = useState(false);
  const [selected, setSelected] = useState([]);
  const [month, setMonth] = useState(null);
  const [amount, setAmount] = useState(0);
  const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert();
  const [isMoreAvailable, setMoreStatus] = useState(false);
  const [page, setPage] = useState(1);
  const [mail, setMail] = useState("");

  useEffect(() => {
    console.log(userStore?.users?.user?.profileType);
    if (userStore?.users?.user?.profileType != "admin") {
      navigate("/home");
    }

    // fetchUser();
  }, []);

  useEffect(() => {
    fetchUser();
  }, [page]);

  const fetchUser = async () => {
    try {
      setLoader(true);

      const payload = {
        page: page,
        _id: userStore.users.user._id,
      };
      const response = await api.getAllUsers(payload);
      console.log(response.user);
      if (response.isRemaining) {
        setMoreStatus(true);
      } else {
        setMoreStatus(false);
      }

      setData([...data, ...response.user]);
      await props.fetchDashboardUsers();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };
  const fetchUserByMail = async () => {
    try {
      if(mail){

        setLoader(true);
        const payload = {
          email: mail.trim(),
          _id: userStore.users.user._id,
        };
        const response = await api.getUserByMail(payload);
        console.log(response.user);
        setData(response.user);
        await props.fetchDashboardUsers();
        setLoader(false);
      }else{
        console.log("Please insert Email");
      }
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const edit = async (paymentType, isPaymentEdit, price) => {
    setLoader(true);

    const sessionAc = await props.checkSession();

    if (!sessionAc) {
      throw new Error("Session Expired");
    }

    try {
      const validator = await validators();

      if (validator) {
        throw new Error(validator);
      }

      if (isPaymentEdit) {
        await cancel(selected, true, paymentType);
      }

      const date = new Date();
      date.setMonth(date.getMonth() + parseInt(month));

      const payload = {
        _id: userStore.users.user._id,
        payment_id: selected?.result[0]?._id,
        paymentCategory: paymentType,
        nextPaymentDate: date,
        lastPaymentDate: new Date(),
        isSubscription: false,
        isOneTimePayment: true,
      };

      const editResponse = await api.editPayment(payload);

      if (!isPaymentEdit) {
        const payHistoryLoad = {
          _id: userStore.users.user._id,
          user: selected?._id,
          amountPaid: amount,
          from: "0", // 0 = admin, 1 = mannual
          paidFrom: "0", // stripe = 1 , crypto = 2, 0 = N/A
          type:
            parseInt(paymentType) == 1
              ? "1"
              : parseInt(paymentType) == 2
              ? "2"
              : null, // 1 = basic, 2 = premimum
        };

        const payResp = await api.createPaymentHistory(payHistoryLoad);
      } else {
        const payHistoryLoad = {
          _id: userStore.users.user._id,
          user: selected?._id,
          amountPaid: amount,
          from: "0", // 0 = mannual, 1 = admin
          paidFrom: "0", // stripe = 1 , crypto = 2, 0 = N/A
          type:
            parseInt(paymentType) == 1
              ? "1"
              : parseInt(paymentType) == 2
              ? "2"
              : null, // 1 = basic, 2 = premimum
        };

        const payResp = await api.editLast(payHistoryLoad);
      }
      if(mail){
        await fetchUserByMail();
      }else{
        await fetchUser();
      }
      setAmount();
      setMonth();
      await props.fetchDashboardUsers();
      alert.show("Successfully Updated User!");

      setPayModal(false);
      setEditPayModal(false);
      setLoader(false);
    } catch (e) {
      console.log(e.message);
      alert.show(e.message);
      setLoader(false);
    }
  };

  const validators = () => {
    if (!month) {
      return "Please Select Month";
    }
    if (!amount || amount < 0) {
      return "Please Enter Valid Amount";
    }

    return false;
  };

  const openPayModel = (item) => {
    setPayModal(true);
    setSelected(item);
  };

  const openEditPayModel = (item) => {
    setEditPayModal(true);
    setSelected(item);
  };

  const openEditPayModel_byUser = (item) => {
    setEditPayModal_byUser(true);
    setSelected(item);
  };

  const cancel = async (item, isEdit, paymentType) => {
    setLoader(true);

    const sessionAc = await props.checkSession();

    if (!sessionAc) {
      throw new Error("Session Expired");
    }

    try {
      const payload_group = {
        _id: userStore.users.user._id,
        userId: item?._id,
        payment_id: item?.result[0]?._id,
      };

      const removeGroupRes = await api.removeFromDiscord(payload_group);

      if (isEdit) {
        const payload = {
          _id: userStore.users.user._id,
          payment_id: selected?.result[0]?._id,
          paymentCategory: paymentType,
        };

        const editResponse = await api.editPayment(payload);
      }

      const subsDelete = await api.deleteSubscription({
        _id: userStore.users.user._id,
        user: item?._id,
      });
      if(mail){
        await fetchUserByMail();
      }else{
        await fetchUser();
      }
      await props.fetchDashboardUsers();
      alert.show("Successfully Cancelled payment");

      console.log(item);

      setLoader(false);
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const monthDiff = (dateTo_) => {
    const dateFrom = new Date();
    const dateTo = new Date(dateTo_);

    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  };

  const onAmountChange = async (e) => {
    setAmount(e.target.value);
  };

  const showMore = async () => {
    try {
      setPage(page + 1);
    } catch (e) {
      console.log(e);
    }
  };

  const resetSearch = async () => {
    window.location.reload()
  };

  return (
    <div className="paymentMain">
      <div className="searchUser">
        <div className="inputMain">
          <input className="form-control" placeholder="Find User by Email" type="text" onChange={(e)=>setMail(e.target.value)} />
          <i className="fa-solid fa-magnifying-glass p-2" onClick={fetchUserByMail}></i>
        </div>
        <div className="edit-account-pay" onClick={()=>resetSearch()}>Reset</div> 
      </div>
      <div className="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col"><div className="w-mc"> Name</div> </th>
              <th scope="col"><div className="w-mc"> Email</div> </th>
              <th scope="col"><div className="w-mc"> Payment Status</div> </th>
              <th scope="col"><div className="w-mc"> Payment Months</div> </th>
              <th scope="col"><div className="w-mc"> Payment Category</div> </th>
              <th scope="col"><div className="w-mc"> Action</div> </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <>
              {(item?.result.length >0 && !item?.isDeleted) && 
              <tr>
                <td scope="row">{item.name}</td>
                <td>{item.email}</td>
                <td>
                  {item?.result[0]?.paymentCategory == "0"
                    ? "In-Active"
                    : item?.result[0]?.paymentCategory == "1"
                    ? "Basic"
                    : "Premium"}
                </td>
                <td>
                  {(item?.result[0]?.paymentCategory == "1" ||
                    item?.result[0]?.paymentCategory == "2") &&
                    monthDiff(item?.result[0]?.nextPayment) + " Months"}
                  {item?.result[0]?.paymentCategory == "0" && "Not Paid Yet"}
                </td>
                <td>
                  {item?.result[0]?.paymentCategory != "0"
                    ? item?.result[0]?.isSubscription
                      ? "Subscription"
                      : item?.result[0]?.isOneTimePayment
                      ? "Pay as you go"
                      : "N/A"
                    : "N/A"}
                </td>
                <td className="btn-tb-r">
                  <div className="adminUserEditBtn">
                    {item?.result[0]?.paymentCategory == "0" && (
                      <>
                        <button
                          onClick={() => openPayModel(item)}
                          className="pl-2 edit-account-pay"
                        >
                          <img src={Dollar} />
                        </button>
                      </>
                    )}

                    {
                    // (item?.result[0]?.paymentCategory == "1" || item?.result[0]?.paymentCategory == "2") &&
                    (item?.result[0]?.paymentCategory != "0") &&
                      {
                        /* item?.currenthistory[0]?.from != "1"  */
                      } && (
                        <>
                          <button
                            onClick={() => cancel(item)}
                            className="pl-2 edit-account-pay"
                          >
                            <img src={Cross} />
                          </button>
                        </>
                      )}
                    {(item?.result[0]?.paymentCategory == "1" ||
                      item?.result[0]?.paymentCategory == "2") &&
                      item?.currenthistory[0]?.from != "1" && (
                        <>
                          <button
                            onClick={() => openEditPayModel(item)}
                            className="pl-2 edit-account-pay"
                          >
                            <img src={Edit} />
                          </button>
                        </>
                      )}
                    <button
                      onClick={() => navigate(`/admin-billing?uid=${item._id}`)}
                      className="pl-2 edit-account-pay"
                    >
                      <img src={Eye} />
                    </button>
                  </div>
                </td>
              </tr>
              }
              <tr class="spacer"><td colspan="100"></td></tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      {isMoreAvailable && (
        <div className="btnShowMoreCont">
          <button className="showMoreButton" onClick={showMore}>
            Show more
          </button>
        </div>
      )}
      {!data.length && (
        <p className="text-center">No users available to show.</p>
      )}

      <Modal
        onBasic={() => edit("1", false)}
        onPremium={() => edit("2", false)}
        OnClose={() => setPayModal(false)}
        adminPaymentModal={true}
        onAmountChange={onAmountChange}
        visible={payModal}
        onSelect={(e) => setMonth(e.target.value)}
      />

      <Modal
        onBasic={() => edit("1", true)}
        onPremium={() => edit("2", true)}
        OnClose={() => setEditPayModal(false)}
        adminPaymentModal={true}
        visible={editpayModal}
        onSelect={(e) => setMonth(e.target.value)}
        onAmountChange={onAmountChange}
      />

      <Modal
        onBasic={() => edit("1", true, process.env.REACT_APP_BASIC)}
        onPremium={() => edit("2", true, process.env.REACT_APP_PREMIUM)}
        OnClose={() => setEditPayModal_byUser(false)}
        adminPaymentModal={true}
        visible={editpayModal_byUser}
        isPaidByUser={true}
      />
      <Loader loader={loader} />
    </div>
  );
}
