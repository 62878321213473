import TenupIcon from "../images/logoSm.png";
import metaIcon from "../images/meta.png";
import bitkeepIcon from "../images/bitkeep.svg";
import walletConnectIcon from "../images/walletConnect.png";
import stripeLogo from "../images/stripeLogo.png";
import visaLogo from "../images/visaLogo.png";
import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import subsIc from "../images/subscription (1).png";
import connIco from "../images/link.png";
import dicConIco from "../images/broken-link.png";
import crossIco from "../images/remove.png";
import crossBlack from "../images/closeM.svg";
import arrowDown from "../images/arrowDown.svg";
import add from "../images/add.png";
import React, { useEffect } from "react";

export function Modal({
  visible,
  text,
  iscontinue,
  onContinue,
  OnClose,
  isClose,
  yesNo,
  wallet,
  onMetaClick,
  onWalletConnectClick,
  onBitkeepClick,
  paymentModal,
  onSelect,
  onSelectChain,
  adminPaymentModal,
  onPremium,
  onBasic,
  onAmountChange,
  subs,
  selectedChain,
  payasgo,
  isPaidByUser,
  isInsufficeintAlertDanger,
  connectedAcc,
  subscribeUsingCrypto,
  disconnect,
  selectedOpt,
  onStripePay,
  isQr,
  qr,
  onClick,
  onChange,
  remove2fa,
  edit_admin,
  email,
  name,
  onCheck,
  signalAndCrypto,
  onCheckUserPermission,
  onFileChange,
  selectedImg,
  signal,
  mode,
  guides,
  guidesCategory,
  inputData,
  videoLinkGuide,
  videoTitle,
  signalreaction,
  reactionView,
  reactionsData,
  isCrypto,
  issuesReplyModal,
  onClickReply,
  onClickEnd,
  replies,
  chatStatus,
  currentUser,
  replyValue,
  phone,
  paymethod,
}) {
  const walletAdd = localStorage.getItem("wallet");

  useEffect(() => {
    console.log(guidesCategory);
  }, []);

  return (
    <>
      {visible && (
        <div className="gloabalModal">
          <div className="modal_visible">
            {/* {!wallet && <img src={TenupIcon} alt="loader" />} */}
            <img
              onClick={OnClose}
              src={crossBlack}
              className={"corssBlackIco-modal"}
            />
            {!wallet && !paymentModal && !adminPaymentModal && <p>{text}</p>}
            {!yesNo && !wallet && (
              <div className="button">
                {iscontinue && <button onClick={onContinue}>Continue</button>}
                {/* {isClose && <button onClick={OnClose}>Close</button>} */}
              </div>
            )}
            {yesNo && !wallet && !paymentModal && !adminPaymentModal && (
              <div className="button">
                {iscontinue && <button onClick={onContinue}>Yes</button>}
                {isClose && <button onClick={OnClose}>No</button>}
              </div>
            )}

            {wallet && !paymentModal && !adminPaymentModal && (
              <div className="modal-heading-wallet">
                <h3>Connect Wallet</h3>
                <div>
                  <img onClick={onMetaClick} src={metaIcon} />
                  <img onClick={onBitkeepClick} src={bitkeepIcon} />
                  <img onClick={onWalletConnectClick} src={walletConnectIcon} />
                </div>
                {/* <button onClick={OnClose}>Close</button> */}
              </div>
            )}

            {!wallet && paymentModal && !adminPaymentModal && subs && (
              <div className="modal-heading-wallet">
                <h3>{text}</h3>
                {walletAdd && selectedOpt == "2" && (
                  <div className="mwalletadd">
                    <h6 className="walletAdd">
                      {walletAdd?.slice(0, 5) + "..." + walletAdd?.slice(-5)}
                    </h6>
                  </div>
                )}

                <div>
                  <div className="payment-select withLogo">
                    {selectedOpt == 1&&
                    <img
                        className="payLogo"
                        src={stripeLogo}
                        alt="arrowDown"
                      />
                    }
                    <TextField
                      id="standard-select-currency-native"
                      select
                      label="Select Payment Method"
                      defaultValue={selectedOpt}
                      onChange={onSelect}
                      SelectProps={{
                        native: true,
                      }}
                      fullWidth
                      variant="standard"
                      name={"method"}
                    >
                      <option value={1}>Stripe</option>
                      {/* <option value={2}>Crypto Pay</option> */}
                    </TextField>
                    <img
                      className="arrowDown"
                      src={arrowDown}
                      alt="arrowDown"
                    />
                  </div>

                  <div className="payment-radio">
                    <div class="cntr mt-2">
                      {selectedOpt == "2" && (
                        <div className="label">
                          <span>Subscription</span>
                        </div>
                      )}
                      <section className="section-rdbtn">
                        <div>
                          <input
                            className="input-rdbtn"
                            type="radio"
                            onChange={onSelect}
                            id="bas"
                            name="membership"
                            value={parseInt(process.env.REACT_APP_BASIC)}
                          />
                          <label className="label-rdbtn" for="bas">
                            <h2 className="inputRadio-head">Monthly</h2>
                            <span className="inputRadio-text">
                              {parseInt(process.env.REACT_APP_BASIC)}
                            </span>
                            <div className="cur">USD/Month</div>
                          </label>
                        </div>
                        <div>
                          <input
                            className="input-rdbtn"
                            type="radio"
                            onChange={onSelect}
                            id="pre"
                            name="membership"
                            value={parseInt(process.env.REACT_APP_PREMIUM)}
                          />
                          <label className="label-rdbtn" for="pre">
                            <h2 className="inputRadio-head">Yearly</h2>
                            <span className="inputRadio-text">
                              {parseInt(process.env.REACT_APP_PREMIUM)}
                            </span>
                            <div className="cur">USD/Year</div>
                          </label>
                        </div>
                      </section>
                    </div>
                  </div>
                  {selectedOpt == "2" && (
                    <div className="payment-select">
                      <TextField
                        id="standard-select-currency-native"
                        select
                        label="Select Chain"
                        // defaultValue={97}
                        onChange={onSelect}
                        SelectProps={{
                          native: true,
                        }}
                        fullWidth
                        variant="standard"
                        name={"chain"}
                        defaultValue={selectedOpt}
                      >
                        <option disabled selected>
                          {" "}
                        </option>
                        <option value={97}>BSC Test Net</option>
                      </TextField>
                    </div>
                  )}
                </div>
                {/* {connectedAcc && (
                  <div class="alert alert-success m-3 mr-3" role="alert">
                    Wallet connected successfully.
                  </div>
                )} */}
                <div className="button_payment">
                  {(connectedAcc || walletAdd) && selectedOpt == "2" && (
                    <button
                      class={"subs_md-btn modal-btn-con"}
                      onClick={subscribeUsingCrypto}
                    >
                      {" "}
                      <img className="ico-modal-subs" src={subsIc} /> Subscribe
                      Now
                    </button>
                  )}
                </div>

                <div className="button_payment">
                  {!connectedAcc && !walletAdd && selectedOpt == "2" && (
                    <button
                      class={"subs_md-btn modal-btn-con "}
                      onClick={onContinue}
                    >
                      <img className="ico-modal-subs" src={connIco} /> Connect
                      Wallet
                    </button>
                  )}

                  {(connectedAcc || walletAdd) && selectedOpt == "2" && (
                    <button
                      class={"subs_md-btn modal-btn-con "}
                      onClick={disconnect}
                    >
                      <img className="ico-modal-subs" src={dicConIco} />{" "}
                      Disconnect Wallet
                    </button>
                  )}
                  {selectedOpt == "1" && (
                    <button
                      class={"subs_md-btn modal-btn-con"}
                      onClick={onStripePay}
                    >
                      {/* <img className="ico-modal-subs" src={subsIc} />  */}
                      Pay now
                    </button>
                  )}

                  {/* <button class={"modal-btn-con mt-2"} onClick={OnClose}>
                    <img className="ico-modal-subs" src={crossIco} /> Close
                  </button> */}
                </div>
                {/* {isInsufficeintAlertDanger && (
                  <div class="alert alert-danger m-3 mr-3" role="alert">
                    Insufficient funds in your wallet account.
                  </div>
                )} */}
                {isInsufficeintAlertDanger && selectedOpt == "2" && (
                  <span class="badge badge-primary bg-danger mt-4 p-2">
                    {" "}
                    Insufficient funds in your wallet account.
                  </span>
                )}
              </div>
            )}

            {!wallet && paymentModal && !adminPaymentModal && payasgo && (
              <div className="modal-heading-wallet">
                <h3>{text}</h3>
                <div>
                  <div className="payment-select withLogo">
                    {/* <div className="label">
                      <span>Method</span>
                    </div> */}
                    <img
                        className="payLogo"
                        src={visaLogo}
                        alt="arrowDown"
                      />
                    <TextField
                      id="standard-select-currency-native"
                      select
                      name={"method"}
                      label="Select Payment Method"
                      onChange={onSelect}
                      SelectProps={{
                        native: true,
                      }}
                      fullWidth
                      variant="standard"
                    >
                      <option selected value={1}>
                        Debit / Credit Card
                      </option>
                    </TextField>
                    <img
                      className="arrowDown"
                      src={arrowDown}
                      alt="arrowDown"
                    />
                  </div>
                  <div className="payment-radio">
                    <div class="cntr mt-2">
                      <section className="section-rdbtn">
                        <div>
                          <input
                            className="input-rdbtn"
                            type="radio"
                            onChange={onSelect}
                            id="bas"
                            name="membership"
                            value={parseInt(process.env.REACT_APP_BASIC)}
                          />
                          <label className="label-rdbtn" for="bas">
                            <h2 className="inputRadio-head">Monthly</h2>
                            <span className="inputRadio-text">
                              {parseInt(process.env.REACT_APP_BASIC)}
                            </span>
                            <div className="cur">USD/Month</div>
                          </label>
                        </div>
                        {/* <div>
                          <input
                            className="input-rdbtn"
                            type="radio"
                            onChange={onSelect}
                            id="pre"
                            name="membership"
                            value={parseInt(process.env.REACT_APP_PREMIUM)}
                          />
                          <label className="label-rdbtn" for="pre">
                            <h2 className="inputRadio-head">Yearly</h2>
                            <span className="inputRadio-text">
                              {parseInt(process.env.REACT_APP_PREMIUM)}
                            </span>
                            <div className="cur">USD/Year</div>
                          </label>
                        </div> */}
                      </section>
                    </div>
                  </div>

                  <div className="payment-select mt-2">
                    <div className="label">
                    {parseInt(paymethod)  == 0 && <span>Please select any one package.</span>}
                        {parseInt(paymethod) == 1 && <span>Month</span>}
                      {parseInt(paymethod)  == 2 && <span>Year</span>}
                    </div>

                    <TextField
                      id="standard-select-currency-native"
                      select
                      label="Select Months"
                      name={"months"}
                      onChange={onSelect}
                      SelectProps={{
                        native: true,
                      }}
                      fullWidth
                      variant="standard"
                    >
                      {/* <option disabled selected></option> */}
                      <option value={1} selected>01</option>
                      <option value={2}>02</option>
                      <option value={3}>03</option>
                      <option value={4}>04</option>
                      <option value={5}>05</option>
                      {parseInt(paymethod) != 2 &&   <option value={6}>06</option> }
                      {parseInt(paymethod) != 2 &&   <option value={7}>07</option> }
                      {parseInt(paymethod) != 2 &&   <option value={8}>08</option> }
                      {parseInt(paymethod) != 2 &&   <option value={9}>09</option> }
                      {parseInt(paymethod) != 2 &&   <option value={10}>10</option> }
                      {parseInt(paymethod) != 2 &&   <option value={11}>11</option> }
                      {parseInt(paymethod) != 2 &&   <option value={12}>12</option> }
                    </TextField>
                  </div>
                </div>
                <div className="button_payment">
                  <button onClick={onContinue} className="mx-auto">
                    Continue
                  </button>
                  {/* <button onClick={OnClose}>Close</button> */}
                </div>
              </div>
            )}

            {adminPaymentModal && (
              <div className="admin-pay-model">
                {!isPaidByUser && (
                  <div className="payment-select-admin">
                    <div className="label">
                      <span>No of Months.</span>
                    </div>
                    <select
                      onChange={onSelect}
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option disabled selected>
                        Select Month
                      </option>
                      <option value={1}>01</option>
                      <option value={2}>02</option>
                      <option value={3}>03</option>
                      <option value={4}>04</option>
                      <option value={5}>05</option>
                      <option value={6}>06</option>
                      <option value={7}>07</option>
                      <option value={8}>08</option>
                      <option value={9}>09</option>
                      <option value={10}>10</option>
                      <option value={11}>11</option>
                      <option value={12}>12</option>
                    </select>

                    <div className="label mt-2">
                      <span>Amount in $</span>
                    </div>
                    <input
                      onChange={onAmountChange}
                      className="modal-amount"
                    ></input>
                  </div>
                )}

                <div className="pay-btn-div-admin">
                  <button onClick={onBasic}>Basic</button>
                  <button onClick={onPremium}>Premium</button>
                  {/* <button onClick={OnClose}>Cancel</button> */}
                </div>
              </div>
            )}

            {isQr && (
              <div className="admin-pay-model">
                <img className={"TWOFAQR"} src={qr} />
                <div className={"TwoFaText"}>
                  <TextField
                    variant="standard"
                    onChange={onChange}
                    label="Please enter 2FA Code"
                  ></TextField>
                </div>
                <div className="pay-btn-div-admin">
                  <button className="mt-4" onClick={onClick}>
                    Verify Code
                  </button>
                </div>
              </div>
            )}
            {remove2fa && (
              <div className="admin-pay-model">
                <div className={"TwoFaText"}>
                  <TextField
                    variant="standard"
                    onChange={onChange}
                    label="Please enter 2FA Code"
                  ></TextField>
                </div>
                <div className="pay-btn-div-admin">
                  <button className="mt-4" onClick={onClick}>
                    Verify and Remove
                  </button>
                </div>
              </div>
            )}

            {edit_admin && (
              <div className="admin-edit-model">
                <div className="input-admin-edit ">
                  <TextField
                    onChange={onChange}
                    name="name"
                    className="form-control me-3"
                    id="outlined-basic"
                    label="User Name"
                    variant="standard"
                    value={name}
                    InputLabelProps={{ shrink: true }}
                  />
                </div>
                <div className="input-admin-edit">
                  <TextField
                    onChange={onChange}
                    name="email"
                    className="form-control me-3"
                    id="outlined-basic"
                    label="Email"
                    InputLabelProps={{ shrink: true }}
                    value={email}
                    variant="standard"
                  />
                </div>
                <div className="input-admin-edit">
                  <TextField
                    onChange={onChange}
                    name="phone"
                    className="form-control me-3"
                    id="outlined-basic"
                    label="Phone"
                    InputLabelProps={{ shrink: true }}
                    value={phone}
                    variant="standard"
                  />
                </div>
                <div className="input-admin-edit">
                  <TextField
                    onChange={onChange}
                    name="oldPassword"
                    className="form-control me-3"
                    id="outlined-basic"
                    type={"password"}
                    label="Old Password"
                    variant="standard"
                  />
                </div>
                <div className="input-admin-edit">
                  <TextField
                    onChange={onChange}
                    name="newPassword"
                    type={"password"}
                    className="form-control me-3"
                    id="outlined-basic"
                    label="New Password"
                    variant="standard"
                  />
                </div>
                <div className="input-admin-edit">
                  <TextField
                    onChange={onChange}
                    name="confirmNewPassword"
                    type={"password"}
                    className="form-control me-3"
                    id="outlined-basic"
                    label="Confirm New Password"
                    variant="standard"
                  />
                  <div className="permitCont mb-2">
                    <span className="PermitSpan">Permissions</span>
                  </div>
                  <div className="mb-3 permissons-check">
                    <FormControlLabel
                      control={<Checkbox value={2} onChange={onCheck} />}
                      label="Payments"
                    />
                    <FormControlLabel
                      control={<Checkbox value={3} onChange={onCheck} />}
                      label="Billings"
                    />
                    <FormControlLabel
                      control={<Checkbox value={4} onChange={onCheck} />}
                      label="Users"
                    />
                    <FormControlLabel
                      control={<Checkbox value={5} onChange={onCheck} />}
                      label="Lives"
                    />
                    <FormControlLabel
                      control={<Checkbox value={6} onChange={onCheck} />}
                      label="Signals"
                    />
                    <FormControlLabel
                      control={<Checkbox value={7} onChange={onCheck} />}
                      label="Crypto"
                    />
                    <FormControlLabel
                      control={<Checkbox value={8} onChange={onCheck} />}
                      label="Guides"
                    />
                    <FormControlLabel
                      control={<Checkbox value={9} onChange={onCheck} />}
                      label="Issues"
                    />
                  </div>
                  <div className="pay-btn-div-admin">
                    <button className="mt-4" onClick={onClick}>
                      Edit
                    </button>
                  </div>{" "}
                </div>
              </div>
            )}
            {signalAndCrypto && (
              <div className="addModal">
                <div className={"Add Signal"}>
                  <div>
                    <TextField
                      variant="standard"
                      onChange={onChange}
                      label="Enter Title"
                      name="signal"
                      value={signal}
                      multiline
                      // rows={3}
                      // maxRows={5}
                    />
                  </div>
                  <div className="mt-3 mb-3 fileUpload-modal">
                    <label>
                      <input
                        name="file"
                        onChange={onFileChange}
                        accept="audio/*,video/*,image/*"
                        type={"file"}
                      />
                      {selectedImg == null && <img src={add} />}
                      {selectedImg && (
                        <img className="img_sel" src={selectedImg} />
                      )}
                    </label>
                  </div>
                  <div className="mb-3 mt-2 permissons-check">
                    <FormControlLabel
                      control={<Checkbox value={1} onChange={onCheckUserPermission} />}
                      label="Basic"
                    />
                    <FormControlLabel
                      control={<Checkbox value={2} onChange={onCheckUserPermission} />}
                      label="Premium"
                    />
                </div>
                </div>
                <div className="pay-btn-div-admin">
                  <button className="mt-4" onClick={onClick}>
                    {mode == 0 ? "Add" : "Edit"}
                  </button>
                </div>
              </div>
            )}

            {guides && (
              <div className="addModal">
                <div className={"Add Signal"}>
                  <div>
                    <TextField
                      variant="standard"
                      onChange={onChange}
                      label="Enter Title"
                      name="guide"
                      value={inputData?.guide}
                      multiline
                      // rows={3}
                      // maxRows={5}
                    />
                    <TextField
                      variant="standard"
                      onChange={onChange}
                      label="Enter Vimeo Video Embed Link"
                      className="mt-2 mb-2"
                      name="video"
                      value={inputData?.video}
                      multiline
                      // rows={3}
                      // maxRows={5}
                    />
                    <TextField
                      id="standard-select-currency-native"
                      select
                      className="mt-2 mb-2"
                      label="Select Category"
                      name={"category"}
                      value={inputData?.category}
                      onChange={onChange}
                      SelectProps={{
                        native: true,
                      }}
                      fullWidth
                      variant="standard"
                    >
                      <option disabled selected></option>
                      {guidesCategory?.map((item) => (
                        <option value={item?._id}>{item?.name}</option>
                      ))}
                    </TextField>
                  </div>
                  <div className="mt-3 mb-3 fileUpload-modal">
                    <label>
                      <input
                        name="file"
                        onChange={onFileChange}
                        accept="audio/*,image/*"
                        type={"file"}
                      />
                      {selectedImg == null && <img src={add} />}
                      {selectedImg && (
                        <img className="img_sel" src={selectedImg} />
                      )}
                    </label>
                  </div>
                </div>
                <div className="pay-btn-div-admin">
                  <button className="mt-4" onClick={onClick}>
                    {mode == 0 ? "Add" : "Edit"}
                  </button>
                </div>
              </div>
            )}
            {videoLinkGuide && (
              <div className="guideModalVideo">
                <p className="guidesvideoTitle">{videoTitle}</p>
                <iframe
                  src={videoLinkGuide}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
                ></iframe>
              </div>
            )}
            {signalreaction && (
              <div className="addModal">
                <div>
                  <TextField
                    variant="standard"
                    onChange={onChange}
                    label="Enter percentage"
                    className="mt-2 mb-2 ml-2"
                    name="reactamount"
                  />
                </div>
                <button className="mt-3 submit-btn-modal-c" onClick={onClick}>
                  Submit
                </button>
              </div>
            )}
            {issuesReplyModal && (
              <div className="addModal">
                <div className="chat-issue-md">
                  {replies?.map((item) => (
                    <>
                      {item.user._id == currentUser._id && (
                        <div className="reply-user-md">
                          <span style={{ whiteSpace: "pre-line" }}>
                            {item.text}
                          </span>
                        </div>
                      )}
                      {item.user._id != currentUser._id && (
                        <div className="reply-admin-md">
                          <span>{item.text}</span>
                        </div>
                      )}
                    </>
                  ))}

                  {!chatStatus && <span>This chat has been ended!</span>}
                </div>
                {chatStatus && (
                  <div>
                    <TextField
                      variant="standard"
                      onChange={onChange}
                      value={replyValue}
                      label="Enter message to reply"
                      className="mt-2 mb-2 ml-2"
                      name="reactamount"
                    />
                  </div>
                )}
                {chatStatus && (
                  <button
                    className="mt-3 submit-btn-modal-c"
                    onClick={onClickReply}
                  >
                    Reply
                  </button>
                )}
                {chatStatus &&
                  (currentUser?.permissions.includes(1) ||
                    currentUser?.permissions.includes(9)) && (
                    <button
                      className="mt-3 submit-btn-modal-c"
                      onClick={onClickEnd}
                    >
                      End
                    </button>
                  )}
              </div>
            )}
            {reactionView && (
              <div className="tableModel">
                <table class=" table">
                  <thead>
                    <tr>
                      <th scope="col">Email</th>
                      {isCrypto && <th scope="col">React</th>}
                      {!isCrypto && <th scope="col">React</th>}
                      {!isCrypto && <th scope="col">Percentage</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {reactionsData?.map((item) => (
                      <>
                        <tr>
                          <td scope="row">{item.user.email}</td>
                          {isCrypto && (
                            <td scope="row">
                              {item.react == "1"
                                ? "Liked"
                                : item.react == "2"
                                ? "Dis-Liked"
                                : item.react == "3"
                                ? "Heart"
                                : ""}
                            </td>
                          )}
                          {!isCrypto && (
                            <td scope="row">
                              {item.react == "1" ? "Profit" : "Loss"}
                            </td>
                          )}
                          {!isCrypto && (
                            <td scope="row">{item.reactAmount}%</td>
                          )}
                        </tr>
                        <tr class="spacer">
                          <td colspan="100"></td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
