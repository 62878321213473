import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../api";
import { Loader } from "./loader";
import moment from "moment";
import { useAlert } from "react-alert";

export function Sessions(props) {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const userStore = useSelector((state) => state.userReducer);
  const [isMoreAvailable, setMoreStatus] = useState(false);
  const [page, setPage] = useState(1);
  const alert = useAlert()

  useEffect(() => {
    // fetchSessions();
  }, []);

  useEffect(() => {
    fetchSessions();
  }, [page]);

  const fetchSessions = async () => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
        page: page,
      };
      const response = await api.getAllSessions(payload);

  
      if (response.isRemaining) {
        setMoreStatus(true);
      } else {
        setMoreStatus(false);
      }

      setData([...data, ...response.data]);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const dateFormat = (datePram) => {
    const date = new Date(datePram);
    return moment(date).format('MMM DD YYYY, h:mm:ss a')
    // return date.toLocaleString();
  };


  const showMore = async () => {
    try {
      setPage(page + 1);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="paymentMain">
      <div className="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Logged In IP</th>
              <th scope="col">Device Name</th>
              <th scope="col">Start Time</th>
              <th scope="col">End TIme</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <>
              <tr>
                <td scope="row">{item.ipAddress}</td>
                <td>{item.device}</td>
                <td>
                  <div className="dateTool">
                    {dateFormat(item?.createdAt).slice(0,11)}
                    <div className="fullDate">{dateFormat(item?.createdAt)}</div>
                  </div>
                </td>
                <td> {item.status ? "On-going Session" : 
                  <div className="dateTool">
                    {dateFormat(item?.updatedAt).slice(0,11)}
                    <div className="fullDate">{dateFormat(item?.updatedAt)}</div>
                  </div>
                  }
                </td>
                <td>
                {item.status ?
                  <div className="badge bg-success">Active</div>
                  :
                  <div className="badge bg-danger">Ended</div>
                }
                </td>
              </tr>
              <tr class="spacer"><td colspan="100"></td></tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      {isMoreAvailable && <div className="btnShowMoreCont"><button className="showMoreButton" onClick={showMore}>Show more</button></div>}
      {!data.length && (
        <p className="text-center">No session logs available to show.</p>
      )}

      <Loader loader={loader} />
    </div>
  );
}
