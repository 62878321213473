import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";

import "./App.css";
import axios from "axios";
import api from "./api";
import userAction from "./redux/users/action";
import socket from "./helpers/socket";
import Router from "./helpers/routers";
import web3Object from "./web3/web3";

function App() {
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const { setUser } = userAction;

  // console.log = () => {};
  // console.error = () => {};
  // console.warn = () => {};

  useEffect(() => {
    localStorage.removeItem("walletconnect");
    localStorage.setItem("wallet", '');

    if (userStore?.users?.token) {
      console.log(userStore?.users?.token);
      axios.defaults.headers.common["Authorization"] = userStore?.users?.token;

      // checkSession();
    }
  }, []);

  socket.on("roomAccepted", function (data) {
    console.log(data);
  });

  if (userStore?.users?.token) {
    socket.on("onNewLogin", function (data) {
      if (data == userStore?.users?.user._id) {
        dispatch(setUser());
        localStorage.removeItem("_id");
        localStorage.removeItem("token");

        console.log("new user is logged in from different account");
      }
    });
  }

  // const checkSession = async () => {
  //   try {
  //     const session = await api.checkSession();

  //     if (!session) {
  //       dispatch(setUser());
  //       window.location.reload()
  //       return false;
  //     }

  //     return true;
  //   } catch (e) {
  //     dispatch(setUser());
  //     window.location.reload()
  //     console.log(e);
  //     return false;
  //   }
  // };

  return (
    <BrowserRouter>
      <Router />
    </BrowserRouter>
  );
}

export default App;
