import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import axios from "axios";
import { io } from "socket.io-client";

import logo from "../images/logo.svg";
import signupCover from "../images/signupCover.png";
import signElement from "../images/signElement.png";
import api from "../api";
import { Loader } from "./loader";
import userAction from "../redux/users/action";
import socket from "../helpers/socket";
import { useAlert } from "react-alert";

export function ForgetPassword(params) {
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const alert = useAlert();

  const { setUser } = userAction;
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (userStore?.users?.token) {
      if (userStore.users.user.profileType == "admin") {
        navigate("/payment-admin");
      } else {
        navigate("/payment");
      }
    }
  }, []);

  const forgetMail = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const validatorsResponse = await validators();

      if (validatorsResponse) {
        throw new Error(validatorsResponse);
      }

      const sendVerifyEmail = await api.sendEmailForgetPass({
        email: data.email,
      });

      alert.show("Please check your mail for Password");
      navigate("/signin");
      setLoader(false);
    } catch (e) {
      // alert.show(e.message);
      alert.show("Invalid Email");
      setLoader(false);
    }
  };

  const validators = () => {
    if (!data.email) {
      return "Email is required";
    }

    return false;
  };

  const onTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div className="signupPage">
      <img className="element" src={signElement} alt="signElement" />
      <div className="logoDiv">
        <img src={logo} alt="logo" />
      </div>
      <div className="sigupRow">
        <div className="container">
          <div className="row jc-sb ai-center">
            <div className="col-lg-6">
              <img
                className="img-fluid signupCover"
                src={signupCover}
                alt="cover"
              />
            </div>
            <div className="col-lg-5">
              <div className="signForm">
                <h2>Hello,</h2>
                <h2 className="mb-4">Reset Your Password</h2>
                <form>
                  <div className="mb-3">
                    <TextField
                      onChange={onTextChange}
                      name="email"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                    />
                  </div>

                  <button
                    type="submit"
                    onClick={forgetMail}
                    className="btn signBtn"
                  >
                    Reset Password
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Loader loader={loader} />
    </div>
  );
}
