import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../api";
import { Loader } from "./loader";
import userAction from "../redux/users/action";
import { useAlert } from "react-alert";
import { Modal } from "./modal";
import helpers from "../helpers/helpers";
import { useNavigate } from "react-router-dom";

export function Admin_Create(props) {
  const [data, setData] = useState({
    name: "",
    email: "",
  });
  const [permissions, serPermissions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [qrModalRemove, setQrModalRemove] = useState(false);
  const [qrSrc, setQrSrc] = useState(null);
  const [codeInput, setCodeInput] = useState();
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const alert = useAlert();
  const navigate = useNavigate();

  const register = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const validatorsResponse = await validators();

      if (validatorsResponse) {
        throw new Error(validatorsResponse);
      }

      // 1: super
      // 2: payment
      // 3: Billing
      // 4: Manage Users
      // 5: Manage Live

      const payload = {
        email: data.email,
        password: data.newPassword,
        name: data.name,
        phone: data.phone,
        profileType: "admin",
        permissions: permissions,
      };
      const registerationResponse = await api.register(payload);

      const createPaymentPayload = {
        _id: registerationResponse._id,
        isAdmin: true
      };
      const createPayment = await api.createPayment(createPaymentPayload);

      alert.show("Admin registered successfully");
      setLoader(false);
      setData();
      serPermissions([]);
      navigate("/manage-admin");
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const validators = () => {
    if (!data.email) {
      return "Email is required";
    }
    if (data.email && !helpers.isValidEmail(data.email)) {
      return "Email is not correctly formatted";
    }
    if (!data.name) {
      return "Name is required";
    }
    if (!data.newPassword) {
      return "Password is required";
    }
    if (!data.phone) {
      return "Phone is required";
    }
    if (!data.confirmNewPassword) {
      return "Confirm Password is required";
    }
    if (data.newPassword !== data.confirmNewPassword) {
      return "Password and Confirm password not matched";
    }
    if (data.newPassword && data.newPassword.length < 8) {
      return "New Password must be 8 characters long";
    }

    return false;
  };

  const onTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const onCheck = (e) => {
    setLoader(true);
    if (permissions.includes(parseInt(e.target.value))) {
      const filteredArray = permissions.filter(
        (item) => item != e.target.value
      );

      serPermissions(filteredArray);
    } else {
      let a = permissions;
      a.push(parseInt(e.target.value));

      serPermissions(a);
    }

    setLoader(false);
  };

  return (
    <div className="profileMain">
      <form>
        <div className="input-cont">
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="name"
              className="form-control me-3"
              id="outlined-basic"
              label="User Name"
              variant="outlined"
            />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="email"
              className="form-control me-3"
              id="outlined-basic"
              label="Email"
              variant="outlined"
            />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="phone"
              className="form-control me-3"
              id="outlined-basic"
              label="Phone"
              variant="outlined"
            />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="newPassword"
              type={"password"}
              className="form-control me-3"
              id="outlined-basic"
              label="New Password"
              variant="outlined"
            />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="confirmNewPassword"
              type={"password"}
              className="form-control me-3"
              id="outlined-basic"
              label="Confirm New Password"
              variant="outlined"
            />
          </div>
          <div className="permitCont">
            <span className="PermitSpan">Permissions</span>
          </div>
          <div className="mb-3 mt-2 permissons-check">
            <FormControlLabel
              control={<Checkbox value={2} onChange={onCheck} />}
              label="Payments"
            />
            <FormControlLabel
              control={<Checkbox value={3} onChange={onCheck} />}
              label="Billings"
            />
            <FormControlLabel
              control={<Checkbox value={4} onChange={onCheck} />}
              label="Users"
            />
            <FormControlLabel
              control={<Checkbox value={5} onChange={onCheck} />}
              label="Lives"
            />
            <FormControlLabel
              control={<Checkbox value={6} onChange={onCheck} />}
              label="Signals"
            />
            <FormControlLabel
              control={<Checkbox value={7} onChange={onCheck} />}
              label="Crypto"
            />
            <FormControlLabel
              control={<Checkbox value={8} onChange={onCheck} />}
              label="Guides"
            />
                <FormControlLabel
              control={<Checkbox value={9} onChange={onCheck} />}
              label="Issues"
            />
          </div>

          <div className="subsBtn">
            <button onClick={register}>Create Admin</button>
          </div>
        </div>
      </form>
      <Loader loader={loader} />
    </div>
  );
}
