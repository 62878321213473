import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import axios from "axios";
import { io } from "socket.io-client";

import logo from "../images/logo.svg";
import signupCover from "../images/signupCover.png";
import signElement from "../images/signElement.png";
import api from "../api";
import { Loader } from "./loader";
import userAction from "../redux/users/action";
import socket from "../helpers/socket";
import { useAlert } from "react-alert";

export function TwoFA(params) {
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { setUser } = userAction;

  const login = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const validatorsResponse = await validators();

      if (validatorsResponse) {
        throw new Error(validatorsResponse);
      }

      const payload = {
        _id: userStore?.users?.user?._id,
        twoFACode: data.facode,
      };
      const registerationResponse = await api.verify2FACode(payload);
      const param = {
        _id: userStore?.users?.user?._id,
      };
      const response = await api.getById(param);

      await dispatch(setUser(response));

      if (!userStore?.users?.user?.emailVerified) {
      const sendVerifyEmail = await api.sendEmail({
        _id: userStore?.users?.user?._id
      });

        navigate("/verify-email");
        return;
      }
      setLoader(false);
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const validators = () => {
    if (!data.facode) {
      return "2FA is required";
    }

    return false;
  };

  const onTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };
  return (
    <div className="signupPage">
      <img className="element" src={signElement} alt="signElement" />
      <div className="logoDiv">
        <img src={logo} alt="logo" />
      </div>
      <div className="sigupRow">
        <div className="container">
          <div className="row jc-sb ai-center">
            <div className="col-lg-6">
              <img
                className="img-fluid signupCover"
                src={signupCover}
                alt="cover"
              />
            </div>
            <div className="col-lg-5">
              <div className="signForm">
                <h2>Hello,</h2>
                <h2 className="mb-4">Verify 2 Factor Authentication</h2>
                <form>
                  <div className="mb-3">
                    <TextField
                      onChange={onTextChange}
                      name="facode"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="2-Factor Auth Code"
                      variant="outlined"
                    />
                  </div>
                  <button type="submit" onClick={login} className="btn signBtn">
                    Verify
                  </button>

                  {/* <div className="alreadySign">
                    <p>
                      Re-login to website <Link to="/signin">Sign-In</Link>
                    </p>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Loader loader={loader} />
    </div>
  );
}
