import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { Loader } from "./loader";
import moment from "moment";
import Edit from "../images/write.png";
import Cross from "../images/cross-sign.png";
import { Modal } from "./modal";
import { useAlert } from "react-alert";

export function Admin_Manage(props) {
  const [data, setData] = useState([]);
  const [inputData, setInputData] = useState({
    email: "",
    name: "",
    phone: ""
  });
  const [selected, setSelected] = useState();
  const [permissions, setPermissions] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [adminEditModal, setAdminEditModal] = useState(false);
  const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert();

  useEffect(() => {
    fetchUserDet();
  }, []);

  const fetchUserDet = async () => {
    try {
      setLoader(true);
      let response;

      const payload = {
        _id: userStore.users.user._id,
      };
      response = await api.getAllAdmin(payload);

      setData(response);
      await props.fetchDashboardUsers();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const removeUser = async (item) => {
    try {
      setLoader(true);
      let response;

      const payload = {
        _id: userStore.users.user._id,
        user: item._id,
      };
      response = await api.removeAdmin(payload);

      await fetchUserDet();
      await props.fetchDashboardUsers();
      alert.show("Admin Deleted Successfully");
      setLoader(false);
    } catch (e) {
      alert.show("Admin Deleted Not Successfull");
      console.log(e);
      setLoader(false);
    }
  };

  const onTextChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  const openAdminModal = (item) => {
    setSelected(item);
    setInputData({
      email: item?.email,
      name: item?.name,
      phone: item?.phone,
    });
    inputData.name = item?.name;
    inputData.email = item?.email;
    inputData.phone = item?.phone;
    setAdminEditModal(true);
  };

  const close = () => {
    setSelected();
    setPermissions([]);
    setInputData({
      name: "",
      email: "",
    });
    setAdminEditModal(false);
  };

  const onCheck = (e) => {
    setLoader(true);

    if (permissions.includes(parseInt(e.target.value))) {
      const filteredArray = permissions.filter(
        (item) => item != e.target.value
      );

      setPermissions(filteredArray);
    } else {
      let a = permissions;
      a.push(parseInt(e.target.value));

      setPermissions(a);
    }

    setLoader(false);
  };

  const editUser = async () => {
    setLoader(true);

    if (inputData.oldPassword && !inputData.newPassword) {
      alert.show("New Password is required");
      setLoader(false);
      return;
    }
    if (inputData.oldPassword && !inputData.confirmNewPassword) {
      alert.show("Confirm New Password is required");
    setLoader(false);
    return;
    }
    if (inputData.newPassword && !inputData.oldPassword) {
    setLoader(false);
    setLoader(false);
    alert.show("Old Password is required");
    return;
    }
    if (inputData.newPassword && !inputData.confirmNewPassword) {
      alert.show("Confirm New Password is required");
    setLoader(false);
    return;
    }
    if (inputData.confirmNewPassword && !inputData.oldPassword) {
      alert.show("Old Password is required");
    setLoader(false);
    return;
    }
    if (inputData.confirmNewPassword && !inputData.newPassword) {
      alert.show("New Password is required");
      setLoader(false);
      return;
    }
    if (inputData.newPassword && inputData.newPassword.length < 8) {
      alert.show("New Password must be 8 characters long");
      setLoader(false);
      return;
    }

    try {
      const payload = {
        _id: userStore?.users?.user?._id,
        user_id: selected?._id,
        email: inputData.email,
        name: inputData.name,
        phone: inputData.phone,
        permissions: permissions,
      };

      if (
        inputData.oldPassword ||
        inputData.newPassword ||
        inputData.confirmNewPassword
      ) {
        payload.password = inputData.newPassword;
        payload.oldPassword = inputData.oldPassword;
      }

      console.log(selected?.email);
      console.log(inputData?.name);

      if (selected?.email != inputData?.email) {
        payload.emailVerified = false;
      }

      const editResponse = await api.edit(payload);

      setLoader(false);
      await fetchUserDet();
      setAdminEditModal(false);
      setSelected();
      setPermissions([]);
      setInputData({
        name: "",
        email: "",
        phone: ""
      });
      alert.show("Admin Updated Successfully");
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  return (
    <div className="paymentMain">
      <div className="table-responsive">
        <table class="table table-admin-manage">
          <thead>
            <tr>
              <th scope="col">User Email</th>
              <th scope="col">User Name</th>
              <th scope="col">Permissions</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <>
              <tr>
                <td scope="row">{item?.email}</td>
                <td scope="row">{item?.name}</td>
                <td scope="row">
                  {/* // 1: super
                      // 2: payment
                    // 3: Billing
                  // 4: Manage Users
                  // 5: Manage Live */}

                  {item?.permissions.includes(2) && (
                    <span className="permission-text-green">Payments</span>
                  )}
                  {item?.permissions.includes(3) && (
                    <span className="permission-text-blue">Billings</span>
                  )}
                  {item?.permissions.includes(4) && (
                    <span className="permission-text-grey">Users</span>
                  )}
                  {item?.permissions.includes(5) && (
                    <span className="permission-text-orange">Lives</span>
                  )}
                  {item?.permissions.includes(6) && (
                    <span className="permission-text-blue">Signals</span>
                  )}
                  {item?.permissions.includes(7) && (
                    <span className="permission-text-grey">Crypto</span>
                  )}
                  {item?.permissions.includes(8) && (
                    <span className="permission-text-green">Guides</span>
                  )}
                  {item?.permissions.includes(9) && (
                    <span className="permission-text-green">Issues</span>
                  )}
                  {!item?.permissions?.length && (
                    <span className="permission-text-red">Not Assigned</span>
                  )}

                </td>
                <td scope="row">
                  <button
                    onClick={(e) => openAdminModal(item)}
                    className="pl-2 manage-admin-btn"
                  >
                    <img src={Edit} />
                  </button>
                  <button
                    onClick={() => removeUser(item)}
                    className="pl-2 manage-admin-btn"
                  >
                    {" "}
                    <img src={Cross} />
                  </button>
                </td>
              </tr>
              <tr class="spacer"><td colspan="100"></td></tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      {!data.length && (
        <p className="text-center">No data available to show.</p>
      )}

      <Modal
        edit_admin={true}
        visible={adminEditModal}
        OnClose={close}
        onCheck={onCheck}
        onChange={onTextChange}
        onClick={editUser}
        email={inputData?.email}
        phone={inputData?.phone}
        name={inputData?.name}
        text="Edit Admin Profile"
      />
      <Loader loader={loader} />
    </div>
  );
}
