import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../api";
import { Loader } from "./loader";
import { useAlert } from "react-alert";
import { Modal } from "./modal";
import helpers from "../helpers/helpers";
import constants from "../helpers/constants";
import moment from "moment";

export function AdminLive(props) {
  const [data, setData] = useState({
    name: "",
    link: "",
    time: "",
  });
  const [isLive, setIsLive] = useState(false);
  const [loader, setLoader] = useState(false);
  const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert();

  useEffect(() => {
    fetchLive();
  }, []);

  const fetchLive = async () => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
      };
      const response = await api.getLive(payload);
      // console.log(response.length);
      if (response.length > 0) {
        let year = parseInt(response[0].time.substring(0, 4));
        let month = parseInt(response[0].time.substring(5, 7));
        let day = parseInt(response[0].time.substring(8, 10));
        let hour = parseInt(response[0].time.substring(11, 13));
        let min = parseInt(response[0].time.substring(14, 16));

        let final = `${month}/${day}/${year} ${hour % 12}:${min}:00 ${
          hour > 11 ? "PM" : "AM"
        } UTC`;
        let locaDate = new Date(final);
        let date = new Date(response[0].time);

        let year_loc = parseInt(locaDate.toLocaleDateString().substring(6, 10));
        let date_loc = parseInt(locaDate.toLocaleDateString().substring(3, 5));
        let month_loc = parseInt(locaDate.toLocaleDateString().substring(0, 2));
        let hour_loc = parseInt(locaDate.toLocaleTimeString().substring(0, 2));
        let min_loc = parseInt(locaDate.toLocaleTimeString().substring(3, 5));

        date_loc = parseInt(date_loc) > 9 ? date_loc : `0${date_loc}`;
        month_loc = parseInt(month_loc) > 9 ? month_loc : `0${month_loc}`;
        hour_loc = parseInt(hour_loc) > 9 ? hour_loc : `0${hour_loc}`;
        min_loc = parseInt(min_loc) > 9 ? min_loc : `0${min_loc}`;


        const utcConISO =
        year_loc +
          "-" +
          month_loc +
          "-" +
          date_loc +
          "T" +
          hour_loc +
          ":" +
          min_loc;


        console.log(utcConISO)
        console.log(year_loc)
        console.log(locaDate)

        setData({
          name: response[0].name,
          link: response[0].link,
          time: utcConISO,
          _id: response[0]._id,
        });

        setIsLive(true);
      } else {
        setIsLive(false);
        setData({
          name: "",
          link: "",
          time: "",
          _id: "",
        });
      }
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  function convertUTCDateToLocalDate(date) {
    var newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }

  const createLive = async (e) => {
    e.preventDefault();
    setLoader(true);
    await props.checkSession();

    try {
      const validatorsResponse = await validators();

      if (validatorsResponse) {
        throw new Error(validatorsResponse);
      }

      const utc = new Date(data.time);
      let date = utc.getUTCDate();
      let month = utc.getUTCMonth();
      let year = utc.getUTCFullYear();
      let mins = utc.getUTCMinutes();
      let hour = utc.getUTCHours();

      date = parseInt(date) > 9 ? date : `0${date}`;
      month = parseInt(month) + 1 > 9 ? month + 1 : `0${month + 1}`;
      hour = parseInt(hour) > 9 ? hour : `0${hour}`;
      mins = parseInt(mins) > 9 ? mins : `0${mins}`;

      const utcConISO =
        year + "-" + month + "-" + date + "T" + hour + ":" + mins;
      const payload = {
        _id: userStore?.users?.user?._id,
        name: data.name,
        link: data.link,
        time: utcConISO,
      };
      const createLive = await api.createLive(payload);

      alert.show("Successfully Created!");
      await fetchLive();
      setLoader(false);
    } catch (e) {
      console.log(e.message);
      alert.show(e.message);
      setLoader(false);
    }
  };

  const edit = async (e) => {
    e.preventDefault();
    setLoader(true);
    await props.checkSession();

    try {
      const validatorsResponse = await validatorsEdit();

      if (validatorsResponse) {
        throw new Error(validatorsResponse);
      }

      const utc = new Date(data.time);
      let date = utc.getUTCDate();
      let month = utc.getUTCMonth();
      let year = utc.getUTCFullYear();
      let mins = utc.getUTCMinutes();
      let hour = utc.getUTCHours();

      date = parseInt(date) > 9 ? date : `0${date}`;
      month = parseInt(month) + 1 > 9 ? month + 1 : `0${month + 1}`;
      hour = parseInt(hour) > 9 ? hour : `0${hour}`;
      mins = parseInt(mins) > 9 ? mins : `0${mins}`;

      const utcConISO =
        year + "-" + month + "-" + date + "T" + hour + ":" + mins;

      const payload = {
        _id: userStore?.users?.user?._id,
        name: data.name,
        link: data.link,
        time: utcConISO,
        liveId: data._id,
        isActive: true,
      };
      const editLive = await api.editLive(payload);

      alert.show("Successfully Edited!");
      await fetchLive();
      setLoader(false);
    } catch (e) {
      console.log(e.message);
      alert.show(e.message);
      setLoader(false);
    }
  };

  const deleteLive = async (e) => {
    e.preventDefault();
    setLoader(true);
    await props.checkSession();

    try {
      const payload = {
        _id: userStore?.users?.user?._id,
        liveId: data._id,
        isActive: false,
      };
      const deleteLive = await api.editLive(payload);

      alert.show("Successfully Deleted!");
      await fetchLive();
      setLoader(false);
    } catch (e) {
      console.log(e.message);
      alert.show(e.message);
      setLoader(false);
    }
  };

  const validators = () => {
    if (!data.name) {
      return "Live name is required";
    }
    if (!data.link) {
      return "Live link is required";
    }
    if (data.link && !constants.regexURL.test(data.link)) {
      return "Live link is not valid";
    }
    if (!data.time) {
      return "Live starting time is required";
    }

    const today = new Date();
    const date = new Date(data.time);

    if (date < today) {
      return "Live cannot be scheduled to past";
    }

    return false;
  };

  const validatorsEdit = () => {
    if (!data.name) {
      return "Live name is required";
    }
    if (!data.link) {
      return "Live link is required";
    }
    if (data.link && !constants.regexURL.test(data.link)) {
      return "Live link is not valid";
    }
    if (!data.time) {
      return "Live starting time is required";
    }

    return false;
  };

  const onTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div className="profileMain">
      <form>
        <div className="input-cont">
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="name"
              className="form-control me-3"
              id="outlined-basic"
              label="Live Title"
              variant="outlined"
              value={data.name}
            />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="link"
              className="form-control me-3"
              id="outlined-basic"
              label="Live Link"
              variant="outlined"
              value={data.link}
            />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="time"
              type={"datetime-local"}
              className="form-control me-3"
              id="outlined-basic"
              InputLabelProps={{ shrink: true }}
              label="Live Time"
              variant="outlined"
              value={data.time}
            />
          </div>
          <div className="subsBtn">
            {isLive && <button onClick={edit}>Edit Live</button>}
            {isLive && (
              <button className="mt-2" onClick={deleteLive}>
                End Live
              </button>
            )}
            {!isLive && <button onClick={createLive}>Schedule Live</button>}
          </div>
        </div>
      </form>
      <Loader loader={loader} />
    </div>
  );
}
