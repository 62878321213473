import React, { useState } from 'react'
import { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { useSelector } from 'react-redux';

import wealthLogo from "../../images/wealthLogo.svg"
import growMarket from "../../images/growMarket.png"
import network from "../../images/network.png"
import expert from "../../images/expert.png"
import growMarket2 from "../../images/growMarket2.svg"
import education from "../../images/education.svg"
import network2 from "../../images/network2.svg"
import financial from "../../images/financial.svg"
import sec1Img from "../../images/sec1Img.png"
import crossBlack from "../../images/closeM.svg";
import thumbnail from "../../images/Thumbnail-01.jpg";
import videoSrc from "../../images/Wealth-Coach-Waqar-Ad-2.mp4";

import "./home.css"
import "../../assets/animate.css"
import {animate} from "../../assets/animate"
import { Link } from 'react-router-dom'
import { Privacy } from './privacy';
import { TOS } from './TOS';

export const HomePage = () => {
  const userStore = useSelector((state) => state.userReducer);

  const [show, setShow] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [bitcoin, setBitcoin] = useState("");
  const [ethereum, setEthereum] = useState("");
  const [bnb, setbnb] = useState("");
  const [xrp, setXrp] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    e.preventDefault()
    setShow(true);
  }

  useEffect(() => {
    animate()
    getCryptoPrice()
  }, [])

  const getCryptoPrice = async() => {
    await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Cbinancecoin%2Cripple&vs_currencies=usd`)
    .then(async result => {
       console.log(result.data);
       if(result.data){
         setBitcoin(result.data?.bitcoin?.usd)
         setEthereum(result.data?.ethereum?.usd)
         setbnb(result.data?.binancecoin?.usd)
         setXrp(result.data?.ripple?.usd)
       }
    })
    .catch(error => {
      console.log('error', error)
       });
  }

  return (
    <div className='landingPage'>
       <nav className="navbar navbar-expand-lg homeNav fixed-top" id="navbar">
        <div className="container">
          <a className="navbar-brand" href="#"><img src={wealthLogo} alt="" /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link" aria-current="page" href="#">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#sec3">Opportunities</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://wealthcoachwaqar.com/signin">Join Now</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">About</a>
              </li>
            </ul>
            {userStore?.users?.user?.profileType  == "admin" ? 
            <Link className="homeBtn dib" to="/payment-admin">DASHBOARD</Link>
            : 
            userStore?.users?.user?.profileType == "Tenup Member" ?
            <Link className="homeBtn dib" to="/payment">DASHBOARD</Link> 
            :
            <Link className="homeBtn dib" to="/signin">SIGN IN</Link> 
             }
          </div>
        </div>
      </nav> 
      <div className="sec1">
        <div className="container">
          <div className="row ai-center">
            <div className="col-lg-7">
              <div className="content">
                <h2 className='animatable fadeInUpLong '>Unlock The Potential Of Cryptocurrency With Wealth Coach Waqar</h2>
                <Link className="homeBtn dib animatable fadeInUpLong animationDelay200" to="/signin">Join Now</Link>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="imgDiv">
                <img className='img-fluid' src={sec1Img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> 
      <div className="sec2">
        <marquee behavior="smooth" direction="left">
          <div className="priceM">
            <div className="price">Bitcoin (BTC)  $ {bitcoin}</div>
            <div className="price">-</div>
            <div className="price">Ethereum (ETH)  $ {ethereum}</div>
            <div className="price">-</div>
            <div className="price">BinanceCoin (BNB)  $ {bnb}</div>
            <div className="price">-</div>
            <div className="price">Ripple (XRP)   $ {xrp}</div>
            <div className="price">-</div>
            <div className="price">Tether (USDT)   $ 1.00</div>
          </div>
        </marquee>
      </div>

      <div className="sec3" id='sec3'>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-4">
              <div className="sec3Card">
                <div className="imgDiv animatable bounceIn">
                  <img src={growMarket} alt="" />
                </div>
                <h2 className='animatable fadeInUpLong animationDelay200'>Access a Growing Market</h2>
                <div className="desc animatable fadeInUpLong animationDelay400">Wealth Coach Waqar is the perfect place to learn about and access the growing cryptocurrency market. Our platform offers access to a wide variety of digital assets, giving you the chance to explore and invest in a range of different cryptocurrencies. With Wealth Coach Waqar, you can unlock the potential of this growing market.</div>
                <Link className="homeBtn dib animatable fadeInUpLong animationDelay400" to="/signin">Learn More</Link>
              </div>
            </div>
            <div className="col-lg-6 mt-4">
              <div className="sec3Card">
                <div className="imgDiv animatable bounceIn">
                  <img src={expert} alt="" />
                </div>
                <h2 className='animatable fadeInUpLong animationDelay200'>Learn from the Experts</h2>
                <div className="desc animatable fadeInUpLong animationDelay400">Wealth Coach Waqar provides you with access to Live Sessions from experts in the space. Our Live Sessions allow you to deepen your understanding of the complex topics related to cryptocurrency. With Wealth Coach Waqar, you'll get the knowledge you need to make informed decisions and take advantage of the potential financial gains.</div>
                <Link className="homeBtn dib animatable fadeInUpLong animationDelay400" to="/signin">Learn More</Link>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div className="sec3Card">
                <div className="row ai-center row2 mx-0">
                  <div className="col-md-6">
                    <h2 className='animatable fadeInUpLong animationDelay200'>Network and Connect</h2>
                    <div className="desc animatable fadeInUpLong animationDelay400">Wealth Coach Waqar also provides an opportunity to network and connect with other like-minded individuals. Our platform is designed to help you build relationships, exchange ideas, and collaborate with other members of the cryptocurrency community. With Wealth Coach Waqar, you can make your financial dreams a reality.</div>
                    <Link className="homeBtn dib animatable fadeInUpLong animationDelay400" to="/signin">Learn More</Link>
                  </div>
                  <div className="col-md-6">
                    <div className="imgDiv2 animatable bounceIn">
                      <img className='img-fluid' src={network} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sec4" id='about'>
        <div className="container">
          <h1 className='animatable fadeInUpLong'>Why Wealth Coach Waqar?</h1>
          <div className="descrip animatable fadeInUpLong animationDelay200">Are you interested in the world of cryptocurrency but don't know where to start? Look no further than Wealth Coach Waqar. Our team of experts is dedicated to helping educate and guide you at every step of your cryptocurrency journey. Here are some of the benefits you can expectwhen you join our community:</div>
          <div className="row">
            <div className="col-md-6 mt-3">
              <Sec4Card image={growMarket2} heading={"Access a Growing Market"} text={"Cryptocurrency is a rapidly expanding market, with new coins and technologies being developed all the time. Wealth Coach Waqar keeps a finger on the pulse of this market, and can help you make informed decisions about which coins to invest in."} />
            </div>
            <div className="col-md-6 mt-3">
              <Sec4Card image={education} heading={"Educational resources "} text={"Whether you are a beginner or an experienced cryptocurrency investor, we have the resources you need to stay ahead of the curve. Our team offers courses, webinars, and other educational materials to help you level up your skills and increase your financial returns."} />
            </div>
            <div className="col-md-6 mt-3">
              <Sec4Card image={network2} heading={"Networking opportunities"} text={"One of the best ways to learn about cryptocurrency is to talk to other investors and industry professionals. Wealth Coach Waqar offers networking events andonline communities where you can connect with others who share your interests and goals."} />
            </div>
            <div className="col-md-6 mt-3">
              <Sec4Card image={financial} heading={"Potential for exceptional financial gains"} text={"While cryptocurrency can be volatile, it also has the potential to generate incredible returns. Our team is dedicated to helping you make smart investments that can help you achieve financial freedom and build generational wealth."} />
            </div>
          </div>
          <div className="descrip mt-4 animatable fadeInUpLong animationDelay200">Why wait? Join Wealth Coach Waqar today and start unlocking the power of cryptocurrency for yourself.</div>
          <Link className="homeBtn dib animatable fadeInUpLong animationDelay200" to="/signin">Learn More</Link>
        </div>
      </div>

      {/* video Sec */}
        <div className="container">
      <section className='videoSec'>
          <h1>Why Wealth Coach Waqar?</h1>
          <div className="desc">Discover the booming cryptocurrency market with Wealth Coach Waqar. Our platform grants you access to an extensive array of digital assets. Empower yourself with Wealth Coach Waqar and capitalize on the enormous potential of this rapidly expanding market. </div>
          <div className="videoDiv">
            <video poster={thumbnail} width={"400px"} controls playsInline>
              <source src={videoSrc} type="video/mp4" />
            </video>
          </div>
      </section>
        </div>
      {/* video Sec */}
      {/* faq section */}
      <div className="container">
        <div className="faqSec">
          <h1>Frequently Asked Questions</h1>
          <div className="accordion" id="myAccordion">
            <div className="accordion-item animatable fadeInUpLong ">
              <h2 className="accordion-header" id="headingOne">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne">What is cryptocurrency?</button>									
              </h2>
              <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                <div className="card-body">
                  <p>Cryptocurrency refers to a digital or virtual currency that uses encryption techniques to regulate the generation of units and verify the transfer of funds. Cryptocurrency operates independently of a central bank and can be transferred directly between individuals.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item animatable fadeInUpLong ">
              <h2 className="accordion-header" id="headingTwo">
                <button type="button" className="accordion-button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">What is Wealth Coach Waqar?</button>
              </h2>
              <div id="collapseTwo" className="accordion-collapse collapse show" data-bs-parent="#myAccordion">
                <div className="card-body">
                  <p>Wealth Coach Waqar is a platform that provides expert guidance and education on the world of cryptocurrency. The platform offers a range of resources, including educational sessions with industry experts, networking opportunities, and investment strategies.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item animatable fadeInUpLong ">
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseThree">What kind of resources does Wealth Coach Waqar offer?</button>                     
              </h2>
              <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                <div className="card-body">
                  <p>Wealth Coach Waqar offers a wide range of educational resources, including live sessions with industry experts, tutorials, and investment strategies. The platform also offers networking opportunities, where members can connect with other investors and share their insights and experiences.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item animatable fadeInUpLong ">
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFour">Who is Wealth Coach Waqar for?</button>                     
              </h2>
              <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                <div className="card-body">
                  <p>Wealth Coach Waqar is for anyone who wants to learn more about cryptocurrency and how to invest in it. The platform is suitable for beginners as well as experienced investors who are looking to expand their knowledge and make smart investment decisions.
</p>
                </div>
              </div>
            </div>
            <div className="accordion-item animatable fadeInUpLong ">
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseFive">How can Wealth Coach Waqar help me achieve financial freedom?</button>                     
              </h2>
              <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                <div className="card-body">
                  <p>Wealth Coach Waqar can help you achieve financial freedom by providing expert guidance and education on cryptocurrency. With our resources and investment strategies, you can learn how to make smart decisions and earn exceptional financial gains.
</p>
                </div>
              </div>
            </div>
            <div className="accordion-item animatable fadeInUpLong ">
              <h2 className="accordion-header" id="headingThree">
                <button type="button" className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseSix">Is cryptocurrency a safe investment?</button>                     
              </h2>
              <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#myAccordion">
                <div className="card-body">
                  <p>Cryptocurrency is a relatively new and volatile market, and like any investment, it comes with risks. However, with the right education and guidance, cryptocurrency can be a lucrative investment opportunity. Wealth Coach Waqar helps you navigate the risks and guide you towards smart investment decisions.

</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
      {/* faq section */}
      <div className="container my-5">
        <div className="joinComunitySec">
          <h2 className='animatable fadeInUpLong'>Join Our Comunity</h2>
          <div className="desc animatable fadeInUpLong animationDelay200">Meet the Wealth Coach Waqar team for platform updates, announcements, and more</div>
          <Link className="homeBtn dib animatable fadeInUpLong animationDelay200" to="/signin">Learn More</Link>
        </div>
      </div>

      <footer>
                <div className="container">
                    <div className="footerRow">
                        <div className="col1">
                            <div className="logoDiv">
                                <img src={wealthLogo} alt="" />
                            </div>
                            <p>Copyright ©️ 2023 WealthCoachWaqar.
                              
                              <br></br> All rights reserved.</p>
                            <a href="mailto:support@wealthcoachwaqar.com" className="mail">
                                Contact Us!
                            </a>
                         
                        </div>
                        <div className="col2">
                            <h6>Usefull Links</h6>
                            <ul>
                                <li><a href="#">Home</a></li>
                                <li><a href="#sec3">Opportunities</a></li>
                                <li><a href="">Join Now</a></li>
                            </ul>
                        </div>
                        <div className="col3">
                            <ul>
                                <li><a href="" onClick={(e)=> {handleShow(e);setShowPrivacy(true)}}>Privacy Policy</a></li>
                                <li><a href="#">Blog </a></li>
                                <li><a href="" onClick={(e)=> {handleShow(e);setShowPrivacy(false)}}>Terms of use</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
      <Modal className='privacyModal' show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="privacyModalBody">
            <img
                onClick={handleClose}
                src={crossBlack}
                className={"corssBlackIco-modal"}
              />
              {showPrivacy?
                <Privacy />
              :
                <TOS />
              }
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}


const Sec4Card = ({image,heading,text}) => {
  return (
      <div className="sec4Card">
          <div className="imgDiv">
            <img src={image} alt="" />
          </div>
          <h2 className='animatable fadeInUpLong animationDelay200'>{heading}</h2>
          <div className="desc animatable fadeInUpLong animationDelay400">{text}</div>
      </div>
  )
}

