import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

const useAuth = (props) => {
  const user = { loggedIn: false };
  const userStore = useSelector((state) => state.userReducer);

  if (userStore?.users?.token && userStore?.users?.user?.emailVerified && userStore?.users?.log?.is2FAVerified) {
    user.loggedIn = true;
  }

  return user && user.loggedIn;
};

const ProtectedRoutes = (props) => {
  const isAuth = useAuth();

  return isAuth ? <Outlet /> : <Navigate to="/signin" />;
};

export default ProtectedRoutes;
