import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { Loader } from "./loader";
import Countdown from "react-countdown";
import moment from "moment";
import crossChain from "../images/crossChain.svg";
import analyzer from "../images/analyzer.svg";
import nft from "../images/nft.svg";
import topup from "../images/topup.svg";
import dapp from "../images/dapp.svg";


export function Live(props) {
  const [data, setData] = useState([]);
  const [isLiveVisible, setIsLiveVisible] = useState(false);
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const userStore = useSelector((state) => state.userReducer);
  const navigate = useNavigate();

  useEffect(() => {
    fetchLive();

    if (userStore?.users?.payment?.paymentCategory == "0") {
      alert.show("In active members cannot watch live.");
      navigate("/payment");
    }
  }, []);

  const fetchLive = async () => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const payload = {
        _id: userStore?.users?.user?._id,
      };
      const response = await api.getLive(payload);

      if (response.length > 0) {
        let year = parseInt(response[0].time.substring(0, 4));
        let month = parseInt(response[0].time.substring(5, 7));
        let day = parseInt(response[0].time.substring(8, 10));
        let hour = parseInt(response[0].time.substring(11, 13));
        let min = parseInt(response[0].time.substring(14, 16));
        let final = `${month}/${day}/${year} ${hour % 12}:${min}:00 ${
          hour > 11 ? "PM" : "AM"
        } UTC`;
        let locaDate = new Date(final);

        console.log(locaDate);
        console.log(response[0].time);

        if (locaDate <= new Date()) {
          setData(response[0]);
          setIsLiveVisible(true);
        }
      }

      setData(response[0]);
      setLoader(false);
    } catch (e) {
      // alert.show("Live not found");
      setLoader(false);
    }
  };

  const dateFormat = (date) => {
    let year = parseInt(date.substring(0, 4));
    let month = parseInt(date.substring(5, 7));
    let day = parseInt(date.substring(8, 10));
    let hour = parseInt(date.substring(11, 13));
    let min = parseInt(date.substring(14, 16));

    let final = `${month}/${day}/${year} ${hour % 12}:${min}:00 ${
      hour > 11 ? "PM" : "AM"
    } UTC`;

    let locaDate = new Date(final);

    return moment(locaDate).format("MMMM Do YYYY, h:mm:ss a");
  };

  const secondDifference = (dateCommingUp) => {
    const dateNow = new Date();

    let year = parseInt(dateCommingUp.substring(0, 4));
    let month = parseInt(dateCommingUp.substring(5, 7));
    let day = parseInt(dateCommingUp.substring(8, 10));
    let hour = parseInt(dateCommingUp.substring(11, 13));
    let min = parseInt(dateCommingUp.substring(14, 16));

    let final = `${month}/${day}/${year} ${hour % 12}:${min}:00 ${
      hour > 11 ? "PM" : "AM"
    } UTC`;

    let locaDate = new Date(final);
    const difference = Date.parse(dateNow) - Date.parse(locaDate);

    return Math.abs(difference);
  };

  return (
    <>
    <div className="liveMain">
      {(userStore?.users?.payment?.paymentCategory == "1" ||
        userStore?.users?.payment?.paymentCategory == "2") && (
        <>
          {data?.link && (
            <span className="livetitle">Live Title: {data?.name}</span>
          )}
          {data?.link && isLiveVisible && (
            <iframe
              src={data?.link + "/embed"}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            ></iframe>
          )}
          {data?.link && isLiveVisible && (
            <iframe
              src={data?.link + "/chat"}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            ></iframe>
          )}
          {data?.link && !isLiveVisible && (
            <>
              <span className="liveTime">
                <div class="alert alert-success" role="alert">
                  Session will start at: {dateFormat(data.time)}{" "}
                </div>
              </span>
              <span className="liveTime_timer">
                <Countdown date={Date.now() + secondDifference(data.time)}>
                  <Completionist />
                </Countdown>
              </span>
            </>
          )}

          {!data?.link && (
            <span className="noLive mt-2">The Team is not LIVE right now.</span>
          )}
        </>
      )}
      {userStore?.users?.payment?.paymentCategory == "0" && (
        <>
          <span className="noLive mt-2">
            You are not allowed to watch this live, You are UNPAID user.
          </span>
        </>
      )}


      <Loader loader={loader} />
    </div>
    <div className="projectsRow row">
        <div className="col-lg col-md-3 col-sm-4 mb-3">
          <ProjectCard title={"Cross chain bridge"} image={crossChain} link={"https://bridge.tenup.io/"} />
        </div>
        <div className="col-lg col-md-3 col-sm-4 mb-3">
          <ProjectCard title={"Tenup Analyzer"} image={analyzer} link={"https://analyzer.tenup.io/"} />
        </div>
        <div className="col-lg col-md-3 col-sm-4 mb-3">
          <ProjectCard title={"Ludo NFT"} image={nft} link={"https://ludonft.tenup.io/"} />
        </div>
        <div className="col-lg col-md-3 col-sm-4 mb-3">
          <ProjectCard title={"Tenup Top Up"} image={topup} link={"https://topup.tenup.io/"} />
        </div>
        <div className="col-lg col-md-3 col-sm-4 mb-3">
          <ProjectCard title={"Tenup DAPP"} image={dapp} link={"https://dapp.tenup.io/virtual-mining"} />
        </div>
    </div>
    </>
  );
}

const Completionist = () => (
  <span>We are live now please reload the window.</span>
);
const ProjectCard = ({title,image,link}) => (
  <a href={link} target="_blank" className="projectCard">
    <div className="title">{title}</div>
    <div className="imgDiv"><img src={image} alt="" /></div>
  </a>
);
