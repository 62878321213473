import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.svg";
import signupCover from "../images/signupCover.png";
import signElement from "../images/signElement.png";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import api from "../api";
import { Loader } from "./loader";
import { useAlert } from "react-alert";
import { Modal } from "./modal";
import helpers from "../helpers/helpers";
import { useSelector } from "react-redux";
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import CountrySelect from "./countrySelect";

export function SignUp(params) {
  const navigate = useNavigate();
  const [modalLogin, setModalLogin] = useState(false);
  const alert = useAlert();
  const userStore = useSelector((state) => state.userReducer);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswor1 = () => setShowPassword1((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleClickShowPassword1 = () => setShowPassword((show) => !show);

  const handleMouseDownPassword1 = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (userStore?.users?.token) {
        if (userStore.users.user.profileType == "admin") {
          navigate("/payment-admin");
        }
        else {
          navigate("/payment");
        }
    }
  }, []);

  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [loader, setLoader] = useState(false);

  const register = async (e) => {
    e.preventDefault();
    setLoader(true);

    try {
      const validatorsResponse = await validators();

      if (validatorsResponse) {
        throw new Error(validatorsResponse);
      }

      const payload = {
        email: data.email,
        password: data.password,
        // name: data.firstName + " " + data.lastName,
        name: data.name,
        profileType: "Tenup Member",
        phone: data.phone,
        country: data.country
      };
      console.log(payload);
      const registerationResponse = await api.register(payload);

      const createPaymentPayload = {
        _id: registerationResponse._id
      }
      const createPayment = await api.createPayment(createPaymentPayload)

      console.log(createPayment);
      console.log(registerationResponse);

      alert.show("User registered successfully");
      setData({
        email: "",
        password: "",
        confirmPassword: "",
      })
      setLoader(false);
      setModalLogin(true);
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  function hasWhiteSpace(s) {
    return /\s/g.test(s);
  }
  const validators = () => {
    // if (!data.firstName) {
    //   return "First name is required";
    // }
    if (!data.name) {
      return "User name is required";
    }
    if (hasWhiteSpace(data.name)) {
      return "Space are not allowed in username";
    }
    if (!data.email) {
      return "Email is required";
    }
    if (!data.country) {
      return "Country is required";
    }
    if (!data.phone) {
      return "Phone is required";
    }
    if (isNaN(data.phone)) {
      return "Phone is not correctly formatted";
    }
    if (data.phone.length > 15 ) {
      return "Phone must be less than 15 number";
    }
    if (data.email && !helpers.isValidEmail(data.email)) {
      return "Email is not correctly formatted";
    }
    if (!data.password) {
      return "Password is required";
    }
    if (data.password && data.password.length < 8) {
      return "Password must be 8 characters long";
    }
    if (!data.confirmPassword) {
      return "Confirm password is required";
    }
    if (data.password !== data.confirmPassword) {
      return "Password and Confirm password not matched";
    }

    return false;
  };

  const onTextChange = (e) => {
    if(e.label){
      setData({ ...data, country: e.label });
    }else{
      setData({ ...data, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className="signupPage">
      <img className="element" src={signElement} alt="signElement" />
      <div className="logoDiv">
        <img src={logo} alt="logo" />
      </div>
      <div className="sigupRow">
        <div className="container">
          <div className="row jc-sb ai-center">
            <div className="col-lg-6">
              <img
                className="img-fluid signupCover"
                src={signupCover}
                alt="cover"
              />
            </div>
            <div className="col-lg-5">
              <div className="signForm">
                <h2>Hello,</h2>
                <h2 className="mb-4">Welcome to Wealth Coach Waqar</h2>
                <form>
                  {/* <div className="mb-3 d-flex">
                    <TextField
                      onChange={onTextChange}
                      name="firstName"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="First Name"
                      variant="outlined"
                    />
                    <TextField
                      onChange={onTextChange}
                      name="lastName"
                      className="form-control"
                      id="outlined-basic"
                      label="Last Name"
                      variant="outlined"
                    />
                  </div> */}
                  <div className="mb-3">
                    <TextField
                      onChange={onTextChange}
                      name="name"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="User Name"
                      variant="outlined"
                      inputProps={{ maxLength: 15 }}
                    />
                  </div>
                  <div className="mb-3">
                    <TextField
                      onChange={onTextChange}
                      name="email"
                      type="email"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="Email"
                      variant="outlined"
                    />
                  </div>
                  <div className="mb-3">
                    <CountrySelect onTextChange={onTextChange} />
                  </div>
                  <div className="mb-3">
                    <TextField
                      onChange={onTextChange}
                      name="phone"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="Phone"
                      variant="outlined"
                      type="number"
                    />
                  </div>
                  <div className="">
                    {/* <TextField
                      type={"password"}
                      onChange={onTextChange}
                      name="password"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="Password"
                      variant="outlined"
                    /> */}
                     <FormControl  variant="outlined" className="w-100 mb-3">
                      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                      <OutlinedInput
                        onChange={onTextChange}
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword1}
                              onMouseDown={handleMouseDownPassword1}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                  </div>
                  <div className="">
                    {/* <TextField
                      type={"password"}
                      onChange={onTextChange}
                      name="confirmPassword"
                      className="form-control me-3"
                      id="outlined-basic"
                      label="Confirm Password"
                      variant="outlined"
                    /> */}
                    <FormControl  variant="outlined" className="w-100 mb-3">
                      <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                      <OutlinedInput
                        onChange={onTextChange}
                        name="confirmPassword"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Confirm Password"
                      />
                    </FormControl>
                  </div>

                  {/* <div className="my-4 form-check">
                                    <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Keep me logged in</label>
                                </div> */}
                  <button
                    type="submit"
                    onClick={register}
                    className="btn signBtn"
                  >
                    Submit
                  </button>

                  <div className="alreadySign">
                    <p>
                      Already have account? <Link to="/signin">Sign-In</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        text={"Your account is created, Login to continue!"}
        visible={modalLogin}
        iscontinue={true}
        isClose={false}
        onContinue={() => navigate("/signin")}
        OnClose={() => setModalLogin(false)}
      />
      <Loader loader={loader} />
    </div>
  );
}
