import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "../api";
import { Loader } from "./loader";
import userAction from "../redux/users/action";
import { useAlert } from "react-alert";
import { Modal } from "./modal";
import helpers from "../helpers/helpers";
import { useNavigate } from "react-router-dom";

export function Profile(props) {
  const [data, setData] = useState({
    name: "",
    email: "",
  });
  const [loader, setLoader] = useState(false);
  const [qrModal, setQrModal] = useState(false);
  const [qrModalRemove, setQrModalRemove] = useState(false);
  const [showSaveAlert, setShowSaveAlert] = useState(false);
  const [qrSrc, setQrSrc] = useState(null);
  const [codeInput, setCodeInput] = useState();
  const userStore = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const alert = useAlert();
  const navigate = useNavigate();

  useEffect(() => {
    setData({
      email: userStore?.users?.user?.email,
      name: userStore?.users?.user?.name,
      phone: userStore?.users?.user?.phone,
      fullName: userStore?.users?.user?.fullName
    });
  }, []);

  const fetchUser = async () => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const param = {
        _id: userStore?.users?.user?._id,
      };
      const response = await api.getById(param);

      dispatch(setUser(response));
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const edit = async (e) => {
    e.preventDefault();
    setLoader(true);
    await props.checkSession();

    try {
      const validatorsResponse = await validators();

      if (validatorsResponse) {
        throw new Error(validatorsResponse);
      }

      const payload = {
        _id: userStore?.users?.user?._id,
        email: data.email,
        name: data.name,
        phone: data.phone,
        fullName: data.fullName
      };

      if (data.oldPassword || data.newPassword || data.confirmNewPassword) {
        payload.password = data.newPassword;
        payload.oldPassword = data.oldPassword;
      }

      if (userStore?.users?.user?.email != data.email) {
        payload.emailVerified = false
      }

      const editResponse = await api.edit(payload);
      const param = {
        _id: userStore?.users?.user?._id,
      };
      const responseOfUser = await api.getById(param);

      await dispatch(setUser(responseOfUser));

      if (userStore?.users?.user?.email != data.email) {
        const sendVerifyEmail = await api.sendEmail({
          _id: userStore?.users?.user?._id
        });
        navigate("/verify-email");
      }

      await fetchUser();
      alert.show("Successfully Updated Profile!");
      setLoader(false);
      setShowSaveAlert(true)
      
      setTimeout(() => {
        setShowSaveAlert(false)
      }, 3000);
    } catch (e) {
      console.log(e.message);
      alert.show(e.message);
      setLoader(false);
    }
  };

  const validators = () => {
    if (!data.email) {
      return "Email is required";
    }
    if (data.email && !helpers.isValidEmail(data.email)) {
      return "Email is not correctly formatted";
    }
    if (!data.name) {
      return "Name is required";
    }
    if (!data.phone) {
      return "Phone is required";
    }
    if (isNaN(data.phone)) {
      return "Phone must be correctly formatted";
    }
    if (data.oldPassword && !data.newPassword) {
      return "New Password is required";
    }
    if (data.oldPassword && !data.confirmNewPassword) {
      return "Confirm New Password is required";
    }
    if (data.newPassword && !data.oldPassword) {
      return "Old Password is required";
    }
    if (data.newPassword && !data.confirmNewPassword) {
      return "Confirm New Password is required";
    }
    if (data.confirmNewPassword && !data.oldPassword) {
      return "Old Password is required";
    }
    if (data.confirmNewPassword && !data.newPassword) {
      return "New Password is required";
    }

    if (data.newPassword && data.newPassword.length < 8) {
      return "New Password must be 8 characters long";
    }

    return false;
  };

  const onTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const openQr = async (e) => {
    e.preventDefault();
    setQrModal(true);
    await connect2FA();
  };

  const connect2FA = async () => {
    try {
      const payloadRes2FA = {
        _id: userStore?.users?.user?._id,
      };
      const gen2FA = await api.generate2FA(payloadRes2FA);

      if (!gen2FA.qr) {
        throw new Error("Error while connection 2FA");
      }

      setQrSrc(gen2FA.qr);
    } catch (e) {
      alert.show("Error while connecting 2FA Auth");
    }
  };

  const verify2fa = async () => {
    try {
      setLoader(true);
      if (!codeInput) {
        alert.show("Code for 2FA is required!");
        return;
      }

      const payload = {
        twoFACode: codeInput,
        _id: userStore?.users?.user?._id,
      };
      const resp = await api.verify2FA(payload);

      console.log(resp);
      setQrModal(false);
      fetchUser();
      setLoader(false);
      alert.show("2FA Verified Successfully");
    } catch (e) {
      console.log(e);
      setQrModal(false);
      alert.show("2FA Verification Failed");
      setLoader(false);
    }
  };

  const delete2FA = async (e) => {
    try {
      e.preventDefault();
      setLoader(true);

      if (!codeInput) {
        alert.show("Code for 2FA is required!");
        return;
      }

      const payload_1 = {
        twoFACode: codeInput,
        _id: userStore?.users?.user?._id,
      };
      const resp = await api.verify2FA(payload_1);

      if (resp) {
        const payload = {
          _id: userStore?.users?.user?._id,
          is2FA: false,
          secret2FA: null,
        };
        const editResponse = await api.edit(payload);
      } else {
        throw new Error("error");
      }

      setCodeInput();
      setQrModalRemove(false);
      fetchUser();
      setLoader(false);
      alert.show("2FA removed successfully");
    } catch (e) {
      alert.show("Error while removing 2FA");
      setLoader(false);
    }
  };

  const rem2faModal = (e) => {
    e.preventDefault();
    setQrModalRemove(true);
  };

  return (
    <div className="profileMain">
      <form>
        <div className="input-cont">
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="name"
              className="form-control me-3"
              id="outlined-basic"
              label="User Name"
              variant="outlined"
              value={data.name}
              disabled
              />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="email"
              className="form-control me-3"
              id="outlined-basic"
              label="Email"
              value={data.email}
              variant="outlined"
              disabled
            />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="fullName"
              className="form-control me-3"
              id="outlined-basic"
              label="Full Name"
              value={data.fullName}
              defaultValue={" "}
              variant="outlined"
            />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="phone"
              type="number"
              className="form-control me-3 phone"
              id="outlined-basic"
              label="Phone"
              value={data.phone}
              variant="outlined"
            />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="oldPassword"
              className="form-control me-3"
              id="outlined-basic"
              type={"password"}
              label="Old Password"
              variant="outlined"
            />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="newPassword"
              type={"password"}
              className="form-control me-3"
              id="outlined-basic"
              label="New Password"
              variant="outlined"
            />
          </div>
          <div className="mb-3">
            <TextField
              onChange={onTextChange}
              name="confirmNewPassword"
              type={"password"}
              className="form-control me-3"
              id="outlined-basic"
              label="Confirm New Password"
              variant="outlined"
            />
          </div>
          <div className="subsBtn">
            <button onClick={edit}>Save</button>
          </div>
          {!userStore?.users?.user?.is2FA && (
            <div className="subsBtn mt-2">
              <button onClick={openQr}>Connect 2-Factor Auth</button>
            </div>
          )}
          {userStore?.users?.user?.is2FA && (
            <div className="subsBtn mt-2">
              <button onClick={(e) => rem2faModal(e)}>Remove 2FA Auth</button>
            </div>
          )}
          {showSaveAlert &&
          <div className="saveAlertM">
            <div className="saveAlert">
              <div>
                <i class="fa-solid fa-check"></i>
                <h1>Saved</h1>
              </div>
            </div>
          </div>
          }
        </div>
      </form>
      <Modal
        isQr={true}
        visible={qrModal}
        OnClose={() => setQrModal(false)}
        qr={qrSrc}
        onChange={(e) => setCodeInput(e.target.value)}
        onClick={verify2fa}
        text="2-Factor Auth"
      />
      <Modal
        visible={qrModalRemove}
        OnClose={() => setQrModalRemove(false)}
        remove2fa={true}
        qr={false}
        onChange={(e) => setCodeInput(e.target.value)}
        onClick={delete2FA}
        text="Remove 2FA Auth"
      />
      <Loader loader={loader} />
    </div>
  );
}
