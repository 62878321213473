import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { Loader } from "./loader";
import { useAlert } from "react-alert";

export function ManageUsers(props) {
  const [data, setData] = useState([]);
  const [exportData, setExport] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isMoreAvailable, setMoreStatus] = useState(false);
  const [page, setPage] = useState(1);
  const userStore = useSelector((state) => state.userReducer);
  const alert = useAlert();

  useEffect(() => {
    console.log(userStore?.users?.user?.profileType);
    if (userStore?.users?.user?.profileType != "admin") {
      navigate("/home");
    }

    // fetchUser();
  }, []);

  useEffect(() => {
    fetchUser();
  }, [page]);


  const fetchUser = async () => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
        page: page,
      };
      const response = await api.getAllUsers(payload);
      console.log(response);
      if (response.isRemaining) {
        setMoreStatus(true);
      } else {
        setMoreStatus(false);
      }

      
      setData([...data, ...response.user]);
      await exportDataFunc();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const edit = async (isBanned, item, index) => {
    setLoader(true);

    const sessionAc = await props.checkSession();

    if (!sessionAc) {
      throw new Error("Session Expired");
    }

    try {
      const payload = {
        _id: userStore.users.user._id,
        user_id: item._id,
        isActive: !isBanned,
      };

      const editResponse = await api.edit(payload);

      if(editResponse?.success){
        let tempData = [...data]
        tempData[index].isActive = editResponse.data.isActive
        setData(tempData)
        // await fetchUser();
        alert.show(`Successfully ${isBanned?"ban":"un ban"} User!`);
      }
      setLoader(false);
    } catch (e) {
      console.log(e.message);
      alert.show(e.message);
      setLoader(false);
    }
  };
  const editDelete = async (isDelete, item, index) => {
    setLoader(true);

    const sessionAc = await props.checkSession();

    if (!sessionAc) {
      throw new Error("Session Expired");
    }

    try {
      const payload = {
        _id: userStore.users.user._id,
        user_id: item._id,
        isDeleted: isDelete,
      };

      const editResponse = await api.edit(payload);

      if(editResponse?.success){
        let tempData = [...data]
        tempData[index].isDeleted = editResponse.data.isDeleted
        setData(tempData)
        // await fetchUser();
        alert.show(`Successfully delete User!`);
      }
      setLoader(false);
    } catch (e) {
      console.log(e.message);
      alert.show(e.message);
      setLoader(false);
    }
  };

  const exportDataFunc = async () => {
    try {
      const dataRes = await api.getExportDataUser({
        _id: userStore?.users?.user?._id,
      });
      setExport(dataRes);
    } catch (e) {
      console.log(e);
    }

  };

  const showMore = async () => {
    try {
      setPage(page + 1);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="paymentMain">
    {data.length  > 0 && exportData.length > 0 &&   <div className="export-user-cont">
        <span className="exportcsv-user">
          <CSVLink data={exportData}>Export as CSV</CSVLink>
        </span>
      </div>
    }
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            {/* <th scope="col">Payment Mode</th> */}
            <th scope="col">Payment Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item,key) => (
            <>
            <tr key={key}>
              <td scope="row">{item.name}</td>
              <td>{item.email}</td>
              {/* <td>{item?.discord ? item.discord : "N/A"}</td>
            <td>{item.isUserInChannel ? "Joined" : "Not Joined"}</td>
            <td>{item.userMetamaskAddress ? item.userMetamaskAddress : "N/A"}</td> */}
              {/* <td>{item?.result[0]?.isOneTimePayment ? "Pay as You Go" : item?.result[0]?.isSubscription ? item?.result[0]?.isSubscription : 'N/A'}</td> */}
              <td>
                {item?.result[0]?.paymentCategory == "0"
                  ? "In-Active"
                  : item?.result[0]?.paymentCategory == "1"
                  ? "Basic"
                  : item?.result[0]?.paymentCategory ? "Premium": "" }
              </td>
              <td>
                <div className="d-flex">
                  {item.isActive && (
                    <button
                      onClick={(e) => edit(true, item,key)}
                      className="ban-account"
                    >
                      Ban Account
                    </button>
                  )}
                  {!item.isActive && (
                    <button
                      onClick={(e) => edit(false, item,key)}
                      className="ban-account"
                    >
                      Un Ban
                    </button>
                  )}
                  {item.isDeleted && (
                    <button
                      className="ban-account bg-red ms-2"
                    >
                      Deleted
                    </button>
                  )}
                  {!item.isDeleted && (
                    <button
                      onClick={(e) => editDelete(true, item, key)}
                      className="ban-account ms-2"
                    >
                      Delete Account
                    </button>
                  )}
                </div>
              </td>
            </tr>
            <tr class="spacer"><td colspan="100"></td></tr>
            </>
          ))}
        </tbody>
      </table>
      {isMoreAvailable && <div className="btnShowMoreCont"><button className="showMoreButton" onClick={showMore}>Show more</button></div>}
      {!data.length && (
        <p className="text-center">No users available to show.</p>
      )}

      <Loader loader={loader} />
    </div>
  );
}
