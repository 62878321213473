import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import userAction from "../redux/users/action";
import discordImg from "../images/discord.png";
import facebook from "../images/facebook.png";
import twitter from "../images/twitter.png";
import discordIco from "../images/discordIco.svg";
import api from "../api";
import { Loader } from "./loader";
import { getAccount, web3Initializer } from "../helpers/metamask";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import web3Object from "../web3/web3";
import { useAlert } from "react-alert";
import { Modal } from "./modal";
import FacebookLogin from "react-facebook-login";

export function Social(props) {
  const userStore = useSelector((state) => state.userReducer);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const alert = useAlert();
  const [walletModal, setWalletModal] = useState(false);

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    const code = params.get("code");
    const channelAuth = localStorage.getItem("channelAuth");

    console.log(channelAuth);

    if (code && channelAuth == "false") {
      discord(code);
    }
    if (code && channelAuth == "true") {
      joinDiscordGroup(code);
    }
  }, []);

  const responseFacebook = (response) => {
    console.log(response);
  };

  const fetchUser = async () => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const param = {
        _id: userStore?.users?.user?._id,
      };
      const response = await api.getById(param);

      dispatch(setUser(response));
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const discord = async (code) => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const id = localStorage.getItem("_id");

      const payload = {
        code: code,
        _id: id,
      };

      const resp = await api.discordAuth(payload);

      if (!resp.discord) {
        throw new Error("Error while connecting");
      }

      alert.show("Successfully Connected!");
      await fetchUser();
      setLoader(false);
    } catch (e) {
      alert.show(
        "Error while connecting discord, Maybe account already linked."
      );
      setLoader(false);
    }
  };

  const discordAuth = async (isChannelAuth) => {
    setLoader(true);

    const sessionAc = await props.checkSession();

    if (!sessionAc) {
      throw new Error("Session Expired");
    }

    localStorage.setItem("channelAuth", isChannelAuth);
    window.open(process.env.REACT_APP_DISCORD_URL, "_self");
  };

  const connectMetamask = async () => {
    try {
      setWalletModal(false);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      setLoader(true);
      await web3Initializer();

      const userWalletId = await getAccount();

      if (!userWalletId) {
        throw new Error("Unable to connect user, Metamask error.");
      }

      const signature = await web3Object.web3.eth.personal.sign(
        `I am signing to connect share my metamask address with tenup private group`,
        userWalletId,
        ""
      );

      if (!signature) {
        throw new Error("Unable to connect user, Signature denied.");
      }

      const payload = {
        _id: userStore?.users?.user?._id,
        userMetamaskAddress: userWalletId,
      };

      const editResponse = await api.edit(payload);

      alert.show("Successfully Connected!");
      await fetchUser();
      setLoader(false);
    } catch (e) {
      setLoader(false);
      alert.show("Error while connecting with metamask");
      setWalletModal(true);
    }
  };

  const connectBitKeep = async () => {
    try {
      setWalletModal(false);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      setLoader(true);

      const isBitKeepInstalled = window.isBitKeep && !!window.bitkeep.ethereum;

      if (!isBitKeepInstalled) {
        throw new Error("Bitkeep wallet not connected!");
      }

      const provider = window.bitkeep && window.bitkeep.ethereum;

      if (!provider) {
        throw new Error("Bitkeep wallet not connected!");
      }

      const accs = await provider.request({
        method: "eth_requestAccounts",
      });

      web3Object.web3 = new Web3(window.bitkeep.ethereum);

      const isConnecttowallet = provider.isConnected();

      if (!isConnecttowallet) {
        throw new Error("Bitkeep wallet not connected!");
      }

      if (accs.length > 0) {
        let accountAddress = accs[0].toLowerCase();

        const signature = await web3Object.web3.eth.personal.sign(
          `I am signing to connect share my bitkeep address with tenup private group`,
          accountAddress,
          ""
        );

        if (!signature) {
          throw new Error("Bitkeep wallet not connected!");
        }

        const payload = {
          _id: userStore?.users?.user?._id,
          userMetamaskAddress: accountAddress,
        };

        const editResponse = await api.edit(payload);

        alert.show("Successfully Connected!");
        await fetchUser();
      } else {
        throw new Error("Bitkeep wallet not connected!");
      }

      setLoader(false);
    } catch (e) {
      alert.show("Error while connecting bitkeep");
      setWalletModal(true);
      setLoader(false);
    }
  };

  const providerBsc = new WalletConnectProvider({
    rpc: {
      56: "https://bsc-dataseed.binance.org/",
      97: "https://data-seed-prebsc-1-s1.binance.org:8545",
      1: "https://mainnet.infura.io/v3/3f2f5e20071a4cbe9dd30a0090ff6402",
      3: "https://data-seed-prebsc-1-s1.binance.org:8545",
      qrcode: true,
      pollingInterval: 12000,
    },
    chainId: parseInt(process.env.REACT_APP_CHAIN_ID),
  });

  const handleWalletConnect = async () => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      setWalletModal(false);

      const providerWallerCon = await providerBsc.enable();
      const web33 = new Web3(providerBsc);

      web3Object.web3 = web33;

      const account = await web33.eth.getAccounts();

      if (!account) {
        throw new Error("wallet not connected!");
      }

      if (account.length > 0) {
        const accountAddress = account[0].toLowerCase();

        const signature = await web3Object.web3.eth.personal.sign(
          `I am signing to connect share my walletconnect address with tenup private group`,
          accountAddress,
          ""
        );

        const payload = {
          _id: userStore?.users?.user?._id,
          userMetamaskAddress: accountAddress,
        };

        const editResponse = await api.edit(payload);

        alert.show("Successfully Connected!");
        await fetchUser();
        if (!signature) {
          throw new Error("wallet not connected!");
        }
      } else {
        throw new Error("wallet not connected!");
      }

      setLoader(false);
    } catch (e) {
      alert.show("Error while connecting wallet connect");
      setWalletModal(true);
      setLoader(false);
      console.log(e);
    }
  };

  const joinDiscordGroup = async (code) => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const id = localStorage.getItem("_id");
      const payload = {
        code: code,
        _id: id,
      };
      const resp = await api.joinDiscordGroup(payload);

      localStorage.removeItem("channelAuth");
      alert.show("Successfully added to channel!");
      setLoader(false);
      await fetchUser();
    } catch (e) {
      alert.show("Account already joined or connected account not matched.");
      setLoader(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="paymentMain">
              <h2 className="text-center font-bold mb-3">Socials</h2>
          <div className="row jc-sb">
            <div className="col-lg-4">
              <h5 className="text-center font-bold mb-3">Our Social groups</h5>
              <div className="subsBtn mb-4">
                <button
                  disabled={userStore?.users?.user?.discord}
                  onClick={(e) => discordAuth(false)}
                >
                  <img src={discordImg} />{" "}
                  {userStore?.users?.user?.discord
                    ? "Discord Connected"
                    : "Connect Discord"}
                </button>
              </div>
              <div className="subsBtn">
                <button>
                  {" "}
                  <img src={facebook} /> Connect Facebook
                </button>

                {/* <FacebookLogin
    appId="1088597931155576"
    autoLoad={true}
    fields="name,email,picture"
    onClick={componentClicked}
    callback={responseFacebook} /> */}
              </div>
              <br></br>

              <div className="subsBtn mb-4">
                <button>
                  {" "}
                  <img src={twitter} /> Connect Twitter
                </button>
              </div>

              <div className="subsBtn">
                <button
                  disabled={userStore?.users?.user?.userMetamaskAddress}
                  onClick={() => setWalletModal(true)}
                >
                  {" "}
                  {userStore?.users?.user?.userMetamaskAddress
                    ? "Wallet Connected"
                    : "Connect Wallet"}
                </button>
              </div>
            </div>

            <div className="col-lg-4">
              <h5 className="text-center font-bold mb-3">Group Status</h5>
              <div className="disgroup">
                <h6 className="dishea"><img className="me-2" src={discordIco} alt="" /> Discord </h6>

                {(userStore?.users?.payment?.paymentCategory === "0" ||
                  !userStore?.users?.user?.discord) && (
                  <button disabled className="subsBtndisable">
                    Not a member
                  </button>
                )}
                {((userStore?.users?.payment?.paymentCategory === "1" &&
                  !userStore?.users?.user?.isUserInChannel &&
                  userStore?.users?.user?.discord) ||
                  (userStore?.users?.payment?.paymentCategory === "2" &&
                    !userStore?.users?.user?.isUserInChannel &&
                    userStore?.users?.user?.discord)) && (
                  <button
                    onClick={(e) => discordAuth(true)}
                    className="subsBtn1"
                  >
                    Join Discord Group
                  </button>
                )}

                {((userStore?.users?.payment?.paymentCategory === "1" &&
                  userStore?.users?.user?.isUserInChannel) ||
                  (userStore?.users?.payment?.paymentCategory === "2" &&
                    userStore?.users?.user?.isUserInChannel)) && (
                  <button disabled className="subsBtndisable">
                    Discord group joined!
                  </button>
                )}
              </div>
              <br></br>

              {/* <div className="disgroup">
                <h6 className="dishea">Facebook Group Status:</h6>
                <button className="subsBtn1">Not A Member</button>
              </div> */}
            </div>
          </div>
        </div>

        <Loader loader={loader} />
        <Modal
          visible={walletModal}
          onWalletConnectClick={handleWalletConnect}
          onBitkeepClick={connectBitKeep}
          onMetaClick={connectMetamask}
          wallet={true}
          OnClose={() => setWalletModal(false)}
        />
      </div>
    </>
  );
}
