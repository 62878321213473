import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Loader } from "./loader";
import { Modal } from "./modal";
import { getAccount, web3Initializer } from "../helpers/metamask";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import web3Object from "../web3/web3";
import { useDispatch, useSelector } from "react-redux";
import api from "../api";
import userAction from "../redux/users/action";
import SubscriptionContract from "../abi/subscription.json";
import USDTContract from "../abi/usdt.json";
import bigNumber from "bignumber.js";
import subsIco from "../images/subscription (1).png";
import payIco from "../images/coin.png";
import web3Education from "../images/web3Education.jpeg";

export function Payment(props) {
  const userStore = useSelector((state) => state.userReducer);
  const [paymentModal_subscribe, setPaymentModal_subscribe] = useState(false);
  const [paymentModal_payasgo, setPaymentModal_payasgo] = useState(false);
  const alert = useAlert();
  const [loader, setLoader] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [walletModal_retry, setWalletModalRetry] = useState(false);
  const [alertDanger, setAlertDanger] = useState(false);
  const [conWallet, setConnWallet] = useState(false);
  const [subscriptionUser, SetUseSubs] = useState(false);
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const [formData, setFormData] = useState({
    method: 1,
    months: 1,
  });
  const [selectedPay, setSelectedPay] = useState(0);

  useEffect(() => {
    getSubs();
  }, []);

  const getSubs = async () => {
    try {
      const payload = {
        _id: userStore?.users?.user?._id,
        user: userStore?.users?.user?._id,
      };
      const data = await api.getSubscription(payload);
      SetUseSubs(data);
    } catch (e) {}
  };

  const connectMetamask = async (isRetry) => {
    try {
      setWalletModal(false);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      setLoader(true);
      await web3Initializer();

      const userWalletId = await getAccount();

      if (!userWalletId) {
        throw new Error("Unable to connect user, Metamask error.");
      }

      const chainId = await web3Object.web3.eth.getChainId();

      if (parseInt(chainId) != parseInt(formData?.chain)) {
        alert.show("Please connect to Binance Test Network");
        throw new Error("Please connect to Binance Test Net");
      }

      const signature = await web3Object.web3.eth.personal.sign(
        `I am signing to subscribe tenup membership from my account: `,
        userWalletId,
        ""
      );

      if (!signature) {
        throw new Error("Unable to connect user, Signature denied.");
      }

      const payload = {
        _id: userStore?.users?.user?._id,
        userMetamaskAddress: userWalletId,
      };
      const editResponse = await api.edit(payload);

      setConnWallet(true);
      localStorage.setItem("wallet", userWalletId);
      await props.fetchAdd();

      if (isRetry) {
        await retryFunc(userWalletId);
      }

      setLoader(false);
    } catch (e) {
      alert.show(e.message);
      // setFormData();
      setWalletModal(false);
      setLoader(false);
      setLoader(false);
    }
  };

  const retryFunc = async (userWalletId) => {
    try {
      const subsContract = new web3Object.web3.eth.Contract(
        SubscriptionContract,
        process.env.REACT_APP_SUBSCRIPTION_ADDRESS
      );
      const processSubs = await subsContract.methods
        .processSubscription(subscriptionUser.subscriptionId)
        .send({ from: userWalletId });

      const date = new Date();

      if (subscriptionUser.amount / 10 ** 18 == process.env.REACT_APP_BASIC) {
        date.setMonth(date.getMonth() + parseInt(1));
      } else {
        date.setFullYear(date.getFullYear() + parseInt(1));
      }

      const payloadEdit = {
        _id: userStore.users.user._id,
        payment_id: userStore.users.payment._id,
        paymentCategory:
          subscriptionUser.amount / 10 ** 18 == process.env.REACT_APP_BASIC
            ? "1"
            : subscriptionUser.amount / 10 ** 18 ==
              process.env.REACT_APP_PREMIUM
            ? "2"
            : "0",
        nextPaymentDate: date,
        lastPaymentDate: new Date(),
        isSubscription: true,
        isOneTimePayment: false,
      };
      const editResponse = await api.editPayment(payloadEdit);
      const payHistoryLoad = {
        _id: userStore.users.user._id,
        user: userStore.users.user._id,
        amountPaid: subscriptionUser.amount / 10 ** 18,
        from: "1", // 1 = mannual, 1 = admin
        paidFrom: "2", // stripe = 1 , crypto = 2, 0 = N/A
        type:
          subscriptionUser.amount / 10 ** 18 ==
          parseInt(process.env.REACT_APP_BASIC)
            ? "1"
            : subscriptionUser.amount / 10 ** 18 ==
              parseInt(process.env.REACT_APP_PREMIUM)
            ? "2"
            : null, // 1 = basic, 2 = premimum
      };
      const payResp = await api.createPaymentHistory(payHistoryLoad);

      const subEdit = await api.editSubs({
        subid: subscriptionUser._id,
        _id: userStore.users.user._id,
        endTime: date,
        status: "1",
      });

      setWalletModalRetry(false);
      setFormData();
      await fetchUser();
      alert.show("Subscription Successful");
      console.log(processSubs);
    } catch (e) {
      alert.show("Subscription Failed");
      setWalletModalRetry(false);
      setFormData();
    }
  };

  const isApproved = async (walletiid) => {
    try {
      setLoader(true);
      const walletAddress = walletiid;
      const usdtContract = new web3Object.web3.eth.Contract(
        USDTContract,
        process.env.REACT_APP_USDT_ADDRESS
      );
      const checkApproved = await usdtContract.methods
        .allowance(walletAddress, process.env.REACT_APP_SUBSCRIPTION_ADDRESS)
        .call();
      const approvedAmount = parseInt(checkApproved) / 10 ** 18;

      if (parseInt(approvedAmount) > 9999) {
        return true;
      }

      return false;
    } catch (e) {
      console.log(e);
      alert.show("Error while checking approved");
      setLoader(false);
      return false;
    }
  };

  function toFixedExp(x) {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split("e-")[1]);
      if (e) {
        x *= Math.pow(10, e - 1);
        x = "0." + new Array(e).join("0") + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split("+")[1]);
      if (e > 20) {
        e -= 20;
        x /= Math.pow(10, e);
        x += new Array(e + 1).join("0");
      }
    }
    return x;
  }

  const approveAllowance = async (walletiid) => {
    try {
      setLoader(true);
      const walletAddress = walletiid;

      const usdtContract = new web3Object.web3.eth.Contract(
        USDTContract,
        process.env.REACT_APP_USDT_ADDRESS
      );
      let balance = await usdtContract.methods.balanceOf(walletAddress).call();
      balance = balance;
      const amountToApprove = new bigNumber(10000 * 10 ** 18);
      console.log(amountToApprove.toString());
      console.log(toFixedExp(amountToApprove.toString()));
      const approvalResponse = await usdtContract.methods
        .approve(
          process.env.REACT_APP_SUBSCRIPTION_ADDRESS,
          toFixedExp(amountToApprove.toString())
        )
        .send({ from: walletAddress });

      return true;
    } catch (e) {
      console.log(e);
      alert.show("Error while approving");
      setLoader(false);
      return false;
    }
  };

  const subscribeUsingCrypto = async () => {
    try {
      const validator = await validators();

      if (validator) {
        throw new Error(validator);
      }

      const date = new Date();

      console.log(window.ethereum._state.account);
      setAlertDanger(false);
      setLoader(true);
      const walletAddress = localStorage.getItem("wallet");
      await props.fetchAdd();

      const subsContract = new web3Object.web3.eth.Contract(
        SubscriptionContract,
        process.env.REACT_APP_SUBSCRIPTION_ADDRESS
      );
      const usdtContract = new web3Object.web3.eth.Contract(
        USDTContract,
        process.env.REACT_APP_USDT_ADDRESS
      );

      let balance = await usdtContract.methods.balanceOf(walletAddress).call();
      balance = balance / 10 ** 18;

      const subsVal = parseInt(formData.membership);
      const subsValToUSDT = new bigNumber(formData.membership * 10 ** 18);

      if (parseInt(balance) <= subsVal) {
        setAlertDanger(true);
        throw new Error("Insufficient Balance");
      }

      // const amountToApprove = new bigNumber(10000 * 10 ** 18);
      // const time = Math.floor(new Date().getTime() / 1000) + 1000;

      const isApprovedRes = await isApproved(walletAddress);

      if (!isApprovedRes) {
        console.log("not approve");
        const approvalResponse = await approveAllowance(walletAddress);
        if (!approvalResponse) {
          throw new Error("Error while approving");
        }
      }

      const noofmonth = 1;

      if (formData.membership == process.env.REACT_APP_BASIC) {
        date.setMonth(date.getMonth() + parseInt(1));
      } else {
        date.setFullYear(date.getFullYear() + parseInt(1));
        noofmonth = 12;
      }

      console.log(formData);

      const subscriptionResp = await subsContract.methods
        .generateSubscription(
          process.env.REACT_APP_PAYEE_ADDRESS,
          process.env.REACT_APP_USDT_ADDRESS,
          subsValToUSDT.toString(),
          4,
          noofmonth
        )
        .send({ from: walletAddress });

      const payHistoryLoad = {
        _id: userStore.users.user._id,
        user: userStore.users.user._id,
        amountPaid: formData.membership,
        from: "1", // 1 = mannual, 1 = admin
        paidFrom: "2", // stripe = 1 , crypto = 2, 0 = N/A
        type:
          subsVal == parseInt(process.env.REACT_APP_BASIC)
            ? "1"
            : subsVal == parseInt(process.env.REACT_APP_PREMIUM)
            ? "2"
            : null, // 1 = basic, 2 = premimum
      };
      const payResp = await api.createPaymentHistory(payHistoryLoad);

      const payloadEdit = {
        _id: userStore.users.user._id,
        payment_id: userStore.users.payment._id,
        paymentCategory:
          subsVal == process.env.REACT_APP_BASIC
            ? "1"
            : subsVal == process.env.REACT_APP_PREMIUM
            ? "2"
            : "0",
        nextPaymentDate: date,
        lastPaymentDate: new Date(),
        isSubscription: true,
        isOneTimePayment: false,
      };
      const editResponse = await api.editPayment(payloadEdit);

      let dateOfStart = new Date(
        subscriptionResp.events?.AddedSubscriber?.returnValues?._startTime *
          1000
      );
      let endDate = new Date(
        subscriptionResp.events?.AddedSubscriber?.returnValues?._startTime *
          1000
      );

      if (formData.membership == process.env.REACT_APP_BASIC) {
        endDate.setMonth(endDate.getMonth() + 1);
      } else {
        endDate.setFullYear(endDate.getFullYear() + 1);
      }

      const pay_subs = {
        _id: userStore.users.user._id,
        user: userStore.users.user._id,
        amount:
          subscriptionResp.events?.AddedSubscriber?.returnValues
            ?._amountRecurring,
        periodType:
          subscriptionResp.events?.AddedSubscriber?.returnValues?._periodType,
        startTime: dateOfStart,
        endTime: endDate,
        status: "1",
        subscriptionId:
          subscriptionResp.events?.AddedSubscriber?.returnValues
            ?._subscriptionId,
        payee:
          subscriptionResp.events?.AddedSubscriber?.returnValues?._payeeAddress,
        tokenAddress:
          subscriptionResp.events?.AddedSubscriber?.returnValues?._tokenAddress,
      };

      const createSubs = await api.createSubscription(pay_subs);

      setLoader(false);
      setWalletModal(false);
      setPaymentModal_subscribe(false);
      setFormData();
      await fetchUser();
      alert.show("Subscribed Successfully!");
      return true;
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
      return false;
    }
  };

  const connectBitKeep = async (isRetry) => {
    try {
      setWalletModal(false);
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const isBitKeepInstalled = window.isBitKeep && !!window.bitkeep.ethereum;

      if (!isBitKeepInstalled) {
        throw new Error("Bitkeep wallet not connected!");
      }

      const provider = window.bitkeep && window.bitkeep.ethereum;

      if (!provider) {
        throw new Error("Bitkeep wallet not connected!");
      }

      const accs = await provider.request({
        method: "eth_requestAccounts",
      });

      web3Object.web3 = new Web3(window.bitkeep.ethereum);
      const isConnecttowallet = provider.isConnected();

      if (!isConnecttowallet) {
        throw new Error("Bitkeep wallet not connected!");
      }

      if (accs.length > 0) {
        const chainId = await web3Object.web3.eth.getChainId();
        setLoader(true);

        if (parseInt(chainId) != parseInt(formData?.chain)) {
          alert.show("Please connect to Binance Test Network");
          throw new Error("Please connect to Binance Test Net");
        }

        let accountAddress = accs[0].toLowerCase();
        setLoader(true);
        const signature = await web3Object.web3.eth.personal.sign(
          `I am signing to subscribe tenup membership from my account: `,
          accountAddress,
          ""
        );

        if (!signature) {
          throw new Error("Bitkeep wallet not connected!");
        }

        const payload = {
          _id: userStore?.users?.user?._id,
          userMetamaskAddress: accountAddress,
        };

        const editResponse = await api.edit(payload);
        // const subs_user = subscribeUsingCrypto(accountAddress);

        localStorage.setItem("wallet", accountAddress);
        await props.fetchAdd();
        setConnWallet(true);

        if (isRetry) {
          await retryFunc(accountAddress);
        }

        setLoader(false);

        await fetchUser();
      } else {
        throw new Error("Bitkeep wallet not connected!");
      }

      setLoader(false);
    } catch (e) {
      // setFormData();
      alert.show("Error while subscribing!");
      setWalletModal(false);
      setLoader(false);
    }
  };

  const providerBsc = new WalletConnectProvider({
    rpc: {
      56: "https://bsc-dataseed.binance.org/",
      97: "https://data-seed-prebsc-1-s1.binance.org:8545",
      1: "https://mainnet.infura.io/v3/3f2f5e20071a4cbe9dd30a0090ff6402",
      3: "https://data-seed-prebsc-1-s1.binance.org:8545",
      qrcode: true,
      pollingInterval: 12000,
    },
    chainId: parseInt(formData?.chain),
  });

  providerBsc.on("chainChanged", (account) => {
    window.location.reload();
  });

  const handleWalletConnect = async (isRetry) => {
    try {
      setLoader(true);
      localStorage.removeItem("walletconnect");
      await props.fetchAdd();

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      setWalletModal(false);

      const providerWallerCon = await providerBsc.enable();
      console.log(providerWallerCon);
      const web33 = new Web3(providerBsc);

      web3Object.web3 = web33;
      console.log(web3Object);

      const account = await web33.eth.getAccounts();
      console.log(account);

      if (!account) {
        throw new Error("wallet not connected!");
      }

      if (account.length > 0) {
        const accountAddress = account[0].toLowerCase();

        const chainId = await web3Object.web3.eth.getChainId();

        if (parseInt(chainId) != parseInt(formData?.chain)) {
          alert.show("Please connect to Binance Test Network");
          throw new Error("Please connect to Binance Test Net");
        }

        const signature = await web3Object.web3.eth.personal.sign(
          `I am signing to subscribe tenup membership from my account: `,
          accountAddress,
          ""
        );

        const payload = {
          _id: userStore?.users?.user?._id,
          userMetamaskAddress: accountAddress,
        };

        const editResponse = await api.edit(payload);

        localStorage.setItem("wallet", accountAddress);
        await props.fetchAdd();
        await fetchUser();
        if (!signature) {
          throw new Error("wallet not connected!");
        }

        setConnWallet(true);
        if (isRetry) {
          await retryFunc(accountAddress);
        }
      } else {
        throw new Error("wallet not connected!");
      }

      setLoader(false);
    } catch (e) {
      alert.show("Error while connecting wallet connect");
      setWalletModal(true);
      setLoader(false);
      console.log(e);
      localStorage.removeItem("walletconnect");
      await props.fetchAdd();
    }
  };

  const validators = () => {
    if (!formData?.method) {
      return "Payment method is required";
    }
    if (!formData?.membership) {
      return "Membership type is required";
    }
    if (!formData?.chain) {
      return "Chain id is required";
    }

    return false;
  };

  const fetchUser = async () => {
    try {
      setLoader(true);

      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const param = {
        _id: userStore?.users?.user?._id,
      };
      const response = await api.getById(param);

      dispatch(setUser(response));
      await getSubs();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const subscription = async () => {
    try {
      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      const validator = await validators();

      if (validator) {
        throw new Error(validator);
      }

      setAlertDanger(false);
      setConnWallet(false);
      setWalletModal(true);
    } catch (e) {
      alert.show(e.message);
      // setPaymentModal_subscribe(false);
    }
  };

  const payasgo = async () => {
    try {
      setLoader(true);
      const sessionAc = await props.checkSession();

      if (!formData.method) {
        throw new Error("Payment method is required");
      }
      if (!formData.membership) {
        throw new Error("Membership type is required");
      }
      if (!formData.months) {
        throw new Error("Duration is required");
      }

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      let type = 0;

      if (formData?.membership != process.env.REACT_APP_BASIC) {
        type = 1;
      }

      const payload = {
        _id: userStore.users.user._id,
        item: formData?.membership == process.env.REACT_APP_BASIC ? 1 : 2,
        months: formData?.months,
        type: type,
      };
      const stripeRes = await api.createStripeOneTimePay(payload);

      if (!stripeRes?.link) {
        throw new Error("Something went wrong");
      }

      window.open(stripeRes?.link, "_self");
      setLoader(false);
      setPaymentModal_subscribe(false);
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const onSelectInp = (e) => {
    if (e.target.name == "membership") {
      console.log(e.target.value);
      setSelectedPay(e.target.value == process.env.REACT_APP_BASIC ? 1 : 2);
    }
    if (e.target.name == "method" && e.target.value == "2") {
      // setFormData({ ...formData, "chain": 97 });
      setFormData({ ...formData, [e.target.name]: e.target.value, chain: 97 });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const close = () => {
    setAlertDanger(false);
    setConnWallet(false);
    setPaymentModal_subscribe(false);
    setFormData({ method: "1" });
  };

  const disconnect = async () => {
    web3Object.web3 = null;
    setAlertDanger(false);
    setConnWallet(false);
    localStorage.removeItem("wallet");
    await props.fetchAdd();
  };

  const validators_stripe = () => {
    if (!formData?.method) {
      return "Payment method is required";
    }
    if (!formData?.membership) {
      return "Membership type is required";
    }

    return false;
  };

  const onStripePay = async () => {
    try {
      setLoader(true);
      const validator = validators_stripe();

      if (validator) {
        throw new Error(validator);
      }

      let monthsfull;

      if (formData?.membership != process.env.REACT_APP_BASIC) {
        monthsfull = 12;
      } else {
        monthsfull = 1;
      }

      const payload = {
        _id: userStore.users.user._id,
        item: formData?.membership == process.env.REACT_APP_BASIC ? 1 : 2,
        months: monthsfull,
      };
      const stripeRes = await api.createStripeSubscription(payload);

      if (!stripeRes?.link) {
        throw new Error("Something went wrong");
      }

      window.open(stripeRes?.link, "_self");
      setLoader(false);
      setPaymentModal_subscribe(false);
    } catch (e) {
      alert.show(e.message);
      setLoader(false);
    }
  };

  const subscribeRetry = async () => {
    setFormData({ ...formData, chain: "97" });
    setWalletModalRetry(true);
  };

  return (


    <div className="paymentMain">
      {/* <div className="paymentBanner">
        <img className="img-fluid" src={web3Education} alt="web3Education" />
      </div> */}
      {userStore?.users?.payment.paymentCategory == "0" &&
        subscriptionUser?.status != "2" && (
          <div
            onClick={() => setPaymentModal_subscribe(true)}
            className="subsBtn mb-4"
          >
            {/* 
            <button>
              <img className="ico-pay-pg" src={subsIco}></img>Subscription
            </button>
            */}

          </div>
        )}
      {(userStore?.users?.payment.paymentCategory == "1" ||
        userStore?.users?.payment.paymentCategory == "2") && (
        <div className="subsBtn mb-4">
          <button disabled>
            {" "}
            <img className="ico-pay-pg" src={subsIco}></img>Subscribed
          </button>
        </div>
      )}
      {subscriptionUser?.status == "2" && (
        <div className="subsBtn mb-4 ml-3">
          <button disabled>Subscribed</button>
        </div>
      )}

      {subscriptionUser?.status == "2" && (
        <div className="subsBtn mb-4 ml-3">
          <p>
            We are unable to complete your payment, Please retry your
            subscription now.
          </p>
          <button onClick={subscribeRetry}>Retry Now</button>
        </div>
      )}

      {userStore?.users?.payment.paymentCategory == "0" &&
        subscriptionUser?.status != "2" && (
          <div
            onClick={() => setPaymentModal_payasgo(true)}
            className="subsBtn"
          >
            
            <button>
              {" "}
              <img className="ico-pay-pg" src={payIco} /> Pay As You Go
            </button>
           

          </div>
        )}


        {/* <h3>Currently, we are accepting only Registrations.</h3> */}

       
      <Modal
        visible={paymentModal_subscribe}
        OnClose={() => close()}
        text="Subscription"
        wallet={false}
        onSelect={onSelectInp}
        paymentModal={true}
        onContinue={subscription}
        subs={true}
        isInsufficeintAlertDanger={alertDanger}
        connectedAcc={conWallet}
        subscribeUsingCrypto={subscribeUsingCrypto}
        disconnect={disconnect}
        selectedOpt={formData?.method}
        selectedChain={formData?.chain}
        onStripePay={onStripePay}
      />

      <Modal
        visible={paymentModal_payasgo}
        OnClose={() => setPaymentModal_payasgo(false)}
        text="Pay As You Go"
        wallet={false}
        onSelect={onSelectInp}
        paymentModal={true}
        paymethod={selectedPay}
        onContinue={payasgo}
        payasgo={true}
      />

      <Modal
        visible={walletModal}
        onWalletConnectClick={() => handleWalletConnect(false)}
        onBitkeepClick={() => connectBitKeep(false)}
        onMetaClick={() => connectMetamask(false)}
        wallet={true}
        OnClose={() => setWalletModal(false)}
      />
      <Modal
        visible={walletModal_retry}
        onWalletConnectClick={() => handleWalletConnect(true)}
        onBitkeepClick={() => connectBitKeep(true)}
        onMetaClick={() => connectMetamask(true)}
        wallet={true}
        OnClose={() => setWalletModalRetry(false)}
      />
      <Loader loader={loader} />

      

      <div className="note">
        <span>Note:</span> <br />
        Experience the freedom of flexible payment options! With our 
        “Pay As You Go” service, you won't be locked into a recurring
        payment plan. You'll only be charged once based on the length
        of your selected subscription.
      </div>

      


    </div>


  );
}
