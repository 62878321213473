import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

const useAuth = () => {
  const user = { loggedIn: false, status: 0 };
  // 0: not logged in
  // 1: logged in and not email verified not 2fa verified
  // 2: logged in and  2fa verfied email not verified
  // 3: logged in and  2fa verfied email verified
  // 4: logged in and  not 2fa verfied email verified

  const userStore = useSelector((state) => state.userReducer);

  if (
    userStore?.users?.token &&
    !userStore?.users?.log?.is2FAVerified &&
    !userStore?.users?.user?.emailVerified
  ) {
    console.log(1);
    user.loggedIn = true;
    user.status = 1;
  }

  if (
    userStore?.users?.token &&
    userStore?.users?.log?.is2FAVerified &&
    !userStore?.users?.user?.emailVerified
  ) {
    console.log(2);
    user.loggedIn = true;
    user.status = 2;
  }

  if (
    userStore?.users?.token &&
    userStore?.users?.log?.is2FAVerified &&
    userStore?.users?.user?.emailVerified
  ) {
    console.log(3);
    user.loggedIn = true;
    user.status = 3;
  }

  if (
    userStore?.users?.token &&
    !userStore?.users?.log?.is2FAVerified &&
    userStore?.users?.user?.emailVerified
  ) {
    console.log(4);
    user.loggedIn = true;
    user.status = 4;
  }

  return user;
};

const AuthProtectedRoutes = (props) => {
  const isAuth = useAuth();
  // 1: logged in and not email verified not 2fa verified
  // 2: logged in and  2fa verfied email not verified
  // 3: logged in and  2fa verfied email verified
  // 4: logged in and  not 2fa verfied email verified

  return ((isAuth.loggedIn && isAuth.status == 1) || (isAuth.loggedIn && isAuth.status == 4)) ? (
    <Outlet />
  ) : (
    <Navigate to="/verify-email" />
  );
};

export default AuthProtectedRoutes;
