import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { Loader } from "./loader";
import Countdown from "react-countdown";
import moment from "moment";
import { TextField } from "@mui/material";
import { Modal } from "./modal";

export function Issues(props) {
  const [data, setData] = useState({
    issue: "",
  });
  const [issue, setIssue] = useState([]);
  const [reply, setRepley] = useState();
  const alert = useAlert();
  const [issuesReplyModal, setIssuesReplyModal] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [issueReplies, setIssueReplies] = useState([]);
  const [loader, setLoader] = useState(false);
  const userStore = useSelector((state) => state.userReducer);
  const navigate = useNavigate();

  useEffect(() => {
    if (userStore?.users?.payment?.paymentCategory == "0") {
      alert.show("In active members cannot raise issues.");
      navigate("/payment");
    }

    // getIssue();
  }, []);
  
  useEffect(() => {
    if(userStore?.users?.user){
      getIssue();
    }
  }, [userStore?.users?.user]);

  const getIssueReply = async (id) => {
    try {
      setLoader(true);
      const payload = {
        issue: id,
        _id: userStore?.users?.user?._id,
      };
      const replyofIssue = await api.getIssuesReply(payload);
      setIssueReplies(replyofIssue);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  console.log(userStore?.users?.user?._id);
  const getIssue = async () => {
    try {
      setLoader(true);
      const payload = {
        raisedBy: userStore?.users?.user?._id,
        _id: userStore?.users?.user?._id,
      };

      console.log(payload);
      let response;
      if (userStore.users.user.profileType == "admin") {
        response = await api.getIssues(payload);
      } else {
        response = await api.getIssuesByUser(payload);
      }

      setIssue(response);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const issueRaise = async () => {
    try {
      if (!data.issue) {
        alert.show("Please enter issue");
        return;
      }
      setLoader(true);

      const dbPayload = {
        issue: data?.issue,
        _id: userStore.users.user._id,
        raisedBy: userStore?.users?.user?._id,
        status: false,
      };
      console.log('====================================');
      console.log(dbPayload);
      console.log('====================================');
      const saveToDb = await api.createIssue(dbPayload);

      const replyPayload = {
        _id: userStore?.users?.user?._id,
        issue: saveToDb._id,
        user: userStore?.users?.user?._id,
        text: data?.issue,
      };
      const replySave = await api.createIssuesReply(replyPayload);

      const payload = {
        _id: userStore?.users?.user?._id,
        issue: data?.issue,
      };
      const issueMail = await api.sendEmailIssue(payload);

      setData({ issue: "" });
      alert.show("Issue raised successfully");
      getIssue();
      setLoader(false);
    } catch (e) {
      setLoader(false);
      console.log(e);
      alert.show("Unable to raise the issue.");
    }
  };

  const onClickEnd = async (issue) => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
        issueId: selectedIssue?._id,
        status: false,
      };

      console.log(payload);

      const response = await api.editIssue(payload);

      setSelectedIssue({ ...selectedIssue, status: false });
      await getIssue();
      setLoader(false);
      alert.show("Issue closed successfully.");
    } catch (e) {
      alert.show("Unable to close the issue.");
      console.log(e);
      setLoader(false);
    }
  };

  const onTextChange = (e) => {
    setData({ ...data, [e?.target?.name]: e?.target?.value });
  };

  const formatDate = (datePram) => {
    const date = new Date(datePram);

    return moment(date).format("MMM DD YYYY, h:mm:ss a");
  };

  const close = () => {
    setIssuesReplyModal(false);
    setSelectedIssue(null);
    setRepley(null);
  };

  const viewIssue = (issue) => {
    getIssueReply(issue._id);
    setSelectedIssue(issue);
    setIssuesReplyModal(true);
  };

  const onClickReply = async () => {
    try {
      setLoader(true);

      const replyPayload = {
        _id: userStore?.users?.user?._id,
        issue: selectedIssue._id,
        user: userStore?.users?.user?._id,
        text: reply,
      };
      const replySave = await api.createIssuesReply(replyPayload);

      setRepley("");
      setLoader(false);
      getIssueReply(selectedIssue._id);

      alert.show("Reply added successfully");
    } catch (e) {
      alert.show("Unable to reply the issue.");
      setLoader(false);
    }
  };

  const onChangeReply = (e) => {
    setRepley(e?.target?.value);
  };

  return (
    <div className="profileMain">
      {userStore?.users?.user?.profileType != "admin" && (
        <form>
          <div className="input-cont">
            <div className="mb-3">
              <TextField
                onChange={onTextChange}
                name="issue"
                className="form-control me-3"
                id="outlined-basic"
                label="Please enter the issue"
                variant="outlined"
                value={data?.issue}
                multiline
              />
            </div>
          </div>
        </form>
      )}
      {userStore?.users?.user?.profileType != "admin" && (
        <div className="issueButton mt-2 mb-5">
          <button onClick={issueRaise}>Raise an issue</button>
        </div>
      )}
      <div className="table-responsive">
        <table class=" table">
          <thead>
            <tr>
              <th scope="col">Issue</th>
              <th scope="col">Status</th>
              <th scope="col">Date Posted</th>
              <th scope="col">Date Updated</th>
              <th scope="col">Actions</th>
              {/* {(userStore?.users?.user?.permissions.includes(1) ||
                userStore?.users?.user?.permissions.includes(9)) && (
                <th scope="col">Action</th>
              )} */}
            </tr>
          </thead>
          <tbody>
            {issue?.map((item) => (
              <>
              <tr>
                <td
                  scope="row"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={item?.issue}
                >
                  <div style={{whiteSpace: "pre-line"}}>
                    {item?.issue?.length > 20
                      ? item?.issue?.slice(0, 20) + "..."
                      : item?.issue}
                  </div>
                </td>
                {console.log(item?.status)}
                <td>{!item?.status ? "Resolved" : "Not Resolved"}</td>
                <td>
                  <div className="dateTool">
                  {formatDate(item?.createdAt).slice(0,11)}
                  <div className="fullDate">{formatDate(item?.createdAt)}</div>
                  </div>
                </td>
                <td>
                  <div className="dateTool">
                  {formatDate(item?.updatedAt).slice(0,11)}
                  <div className="fullDate">{formatDate(item?.updatedAt)}</div>
                  </div>
                </td>
                <td>
                  <button
                    className="issueButtons"
                    onClick={() => viewIssue(item)}
                  >
                    View
                  </button>
                </td>
              </tr>
              <tr class="spacer"><td colspan="100"></td></tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      <Modal
        text={"Manage Issue"}
        visible={issuesReplyModal}
        issuesReplyModal={issuesReplyModal}
        onChange={onChangeReply}
        OnClose={close}
        replyValue = {reply}
        onClickReply={onClickReply}
        onClickEnd={onClickEnd}
        replies={issueReplies}
        chatStatus={selectedIssue?.status}
        currentUser={userStore?.users?.user}
      />
      <Loader loader={loader} />
    </div>
  );
}
