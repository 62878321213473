import { useEffect, useState } from "react";
import logo from "../images/logo.svg";
import bell from "../images/bell.svg";
import power from "../images/power.svg";
import ibtisam from "../images/ibtisam.jpg";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  NavLink,
  useParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Payment } from "./payment";
import { Social } from "./social";
import { useDispatch, useSelector } from "react-redux";
import userAction from "../redux/users/action";
import Dropdown from "react-bootstrap/Dropdown";
import { Profile } from "./profile";
import api from "../api";
import editIcon from "../images/editIcon.png";
import banner from "../images/banner.png";
import NFTBanner from "../images/NFTBanner.png";
import accessOfGrowing from "../images/accessOfGrowing.png";
import learnFromExpert from "../images/learnFromExpert.png";
import stakingBanner from "../images/stakingBanner.png";
import { Loader } from "./loader";
import { Billing } from "./Billing";
import { Live } from "./live";
import { io } from "socket.io-client";
import { useAlert } from "react-alert";
import { Modal } from "./modal";
import { Sessions } from "./sessions";
import { ManageUsers } from "./admin-users";
import { Payment_Admin } from "./admin_payment";
import { AdminLive } from "./admin-live";
import { Billing_Admin } from "./admin-billing";
import paymentIc from "../images/coin.png";
import billingIc from "../images/invoice (1).png";
import socialIc from "../images/world.png";
import liveIc from "../images/live.png";
import profileIc from "../images/user.png";
import cryptoIco from "../images/crypto.png";
import guideIco from "../images/guide.png";
import signalIco from "../images/signal.png";
import sessionsIc from "../images/laptop.png";
import deleteIco from "../images/delete.png";
import { ReactComponent as PaymentIco } from "../images/paymentIco.svg";
import { ReactComponent as BillingIco } from "../images/billingIco.svg";
import { ReactComponent as SocialIco } from "../images/socialIco.svg";
import { ReactComponent as ProfileIco } from "../images/profileIco.svg";
import { ReactComponent as LiveIco } from "../images/liveIco.svg";
import { ReactComponent as SessionIco } from "../images/sessionIco.svg";
import { ReactComponent as SignalsIco } from "../images/signalsIco.svg";
import { ReactComponent as CryptoIco } from "../images/cryptoIco.svg";
import { ReactComponent as GuidesIco } from "../images/guidesIco.svg";
import { ReactComponent as SupportIco } from "../images/supportIco.svg";
import investment from "../images/investment.svg";
import passiveIncome from "../images/passiveIncome.svg";
import swap from "../images/swap.svg";

import { Admin_Create } from "./admin_create";
import { Admin_Manage } from "./admin_manage";
import { Signals } from "./Signals";
import { CryptoUpdates } from "./CryptoUpdates";
import { Guides } from "./Guides";
import { Issues } from "./issues";


import moment from "moment";




export function Home(props) {
  const { page } = useParams();
  const dispatch = useDispatch();
  const { setUser } = userAction;
  const userStore = useSelector((state) => state.userReducer);
  const [loader, setLoader] = useState(false);
  const [modalLogout, setModalLogout] = useState(false);
  const [inactive, setInactive] = useState();
  const [basic, setBasic] = useState();
  const [premium, setPremium] = useState();
  const [total, setTotal] = useState();
  const [totalOfMonth, setTotalMonth] = useState();
  const [totalOfDay, setTotalDay] = useState();
  const [totalOfWeek, setTotalWeek] = useState();
  const [addressWal, setAddressWal] = useState();
  const [notifications, setNotifications] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showmoreBTN, setshowmoreBTN] = useState(false);

  const alert = useAlert();

  useEffect(() => {
    setAddressWal(localStorage.getItem("wallet"));
    if (userStore?.users?.user?.profileType == "admin") {
      fetchDashboardData();
    }

    getNotificationsDefault()

  }, [userStore?.users?.user]);

  useEffect(() => {
    if (userStore?.users?.user?.profileType == "admin") {
      fetchDashboardData();
    }
  }, [userStore?.users?.token]);

  const logout = async () => {
    try {
      setLoader(true);
      const payload = {
        _id: userStore?.users?.user?._id,
      };
      const logoutUserRes = await api.logout(payload);

      dispatch(setUser());
      alert.show("Logged out successfully");
      setLoader(false);
      localStorage.removeItem("_id");
      localStorage.removeItem("token");
    } catch (e) {
      alert.show("Logged out successfully");
      dispatch(setUser());
      setLoader(false);
    }
  };

  const fetchUser = async () => {
    alert()
    try {
      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      setLoader(true);

      const param = {
        _id: userStore?.users?.user?._id,
      };
      const response = await api.getById(param);


      dispatch(setUser(response));
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchDashboardData = async () => {
    if (userStore?.users?.user?.profileType == "admin") {
      fetchInActive();
      fetchBasic();
      fetchPremium();
      fetchTotalAmount();
      fetchTotalAmountMonth();
      fetchTotalAmountDay();
      fetchTotalAmountWeek();
    }
  };

  const fetchInActive = async () => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
      };
      const response = await api.getInactivePay(payload);

      // paymentTotalLength.inactive = response.t_length;
      setInactive(response.t_length);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchTotalAmount = async () => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
      };
      const response = await api.getTotalAmount(payload);

      setTotal(response.total);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchTotalAmountMonth = async () => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
      };
      const response = await api.getTotalAmount_month(payload);

      setTotalMonth(response.total);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchTotalAmountWeek = async () => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
      };
      const response = await api.getTotalAmount_week(payload);

      setTotalWeek(response.total);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchTotalAmountDay = async () => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
      };
      const response = await api.getTotalAmount_day(payload);

      setTotalDay(response.total);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchBasic = async () => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
      };
      const response = await api.getBasicPay(payload);

      setBasic(response.t_length);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const fetchPremium = async () => {
    try {
      setLoader(true);

      const payload = {
        _id: userStore.users.user._id,
      };
      const response = await api.getPremiumPay(payload);

      setPremium(response.t_length);
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const onProfileFileChange = async (e) => {
    try {
      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      setLoader(true);
      const formData = new FormData();

      formData.append("image", e.target.files[0], e.target.files[0].name);

      const uploadRes = await api.uploadFile(formData);
      const payload = {
        _id: userStore?.users?.user?._id,
        profileImagePath: uploadRes[0],
        profileImageFormat: uploadRes[1],
      };
      const editUser = await api.edit(payload);
      await fetchUser();

      setLoader(false);
    } catch (e) {
      alert.show("File upload failed");
      setLoader(false);
    }
  };

  const fetchAdd = async () => {
    setAddressWal(localStorage.getItem("wallet"));
  };

  const onBannerFileChange = async (e) => {
    try {
      const sessionAc = await props.checkSession();

      if (!sessionAc) {
        throw new Error("Session Expired");
      }

      setLoader(true);
      const formData = new FormData();

      formData.append("image", e.target.files[0], e.target.files[0].name);

      const uploadRes = await api.uploadFile(formData);
      const payload = {
        _id: userStore?.users?.user?._id,
        profileBannerPath: uploadRes[0],
        profileBannerFormat: uploadRes[1],
      };
      const editUser = await api.edit(payload);
      await fetchUser();

      setLoader(false);
    } catch (e) {
      alert.show("File upload failed");
      setLoader(false);
    }
  };

  const closeLeftBar = () => {
    document.getElementById("leftSideBar").style.left = "-230px"
  }
  const openLeftSideBar = () => {
    document.getElementById("leftSideBar").style.left = "0px"
  }

  async function getNotificationsDefault() {
    const userID = userStore.users.user._id;
    const userCreatedAt = userStore.users.user.createdAt;
    const userPermission = userStore.users.payment.paymentCategory;
    const response = await api.fetchNotifications(userID, userPermission, userCreatedAt, 0);

    console.log("notifications", response);
    setNotifications(response.data)
    setNotificationCount(response.count)
    if (response.count === (response.data).length) {
      setshowmoreBTN(false)
    } else {
      setshowmoreBTN(true)
    }

  }

  async function updateNotifications() {
    const userID = userStore.users.user._id;
    const userCreatedAt = userStore.users.user.createdAt;
    const userPermission = userStore.users.payment.paymentCategory;
    const response = await api.fetchNotifications(userID, userPermission, userCreatedAt, 0);

    const notify = (response.data)
    let arr1ids = notifications.map(a => a._id);
    let arr2ids = notify.map(a => a._id);
    let final = [];
    arr2ids.forEach((a, i) => {
      if (!arr1ids.includes(a)) {
        final.push(notify[i]);
      }
    })

    final.forEach(n => {
      alert.show(n.msg);
    });
    setNotifications(response.data)
    setNotificationCount(response.count)
    if (response.count === (response.data).length) {
      setshowmoreBTN(false)
    } else {
      setshowmoreBTN(true)
    }
  }

  async function showmore() {
    const userID = userStore.users.user._id;
    const userCreatedAt = userStore.users.user.createdAt;
    const userPermission = userStore.users.payment.paymentCategory;
    const response = await api.fetchNotifications(userID, userPermission, userCreatedAt, notifications.length);

    console.log([...notifications, ...response.data], response.count);
    setNotifications([...notifications, ...response.data]);
    setNotificationCount(response.count)
    if (response.count === [...notifications, ...response.data].length) {
      setshowmoreBTN(false)
    } else {
      setshowmoreBTN(true)
    }
  }


  async function seenSingle(id) {
    const userID = userStore.users.user._id;

    const response = await api.seenSingleNotification(id, userID);
    console.log("seen single", response);
    // setNotifications(response.data)
    getNotificationsDefault()
  }

  async function seenAll() {
    const userID = userStore.users.user._id;
    const ids = notifications.map(e => e._id)
    console.log(ids)
    const payload = { ids };

    const response = await api.seenAllNotification(userID, payload);
    console.log("seen all", response);
    // setNotifications(response.data)
    getNotificationsDefault()
  }

  function timeConverter(UNIX_timestamp) {
    var a = ((UNIX_timestamp / 1000));
    return moment.unix(a).format("MMM DD YYYY, h:mm:ss a");
  }

  function URL_Redirection(msg) {
    if (msg === "Here is new crypto updates for you.") {
      return "/crypto-updates"
    }
    if (msg === "Checkout new guides") {
      return "/guides"
    }
    if (msg === "Our Live session is going to strat from now.") {
      return "/live"
    }
    if (msg === "New signal have been posted.") {
      return "/signals"
    }

  }





  return (
    <div className="homePage">
      <div className="container">
        <div className="navbarCustome">
          <div className="logoDiv">
            <img src={logo} alt="tenup" />
          </div>

          <div className="linkDiv">
            <div className="notify">
              <Dropdown>
                <Dropdown.Toggle variant="" onDrop={"center"} id="dropdown-basic" className="copyLink shadow-none">
                  <img src={bell} alt="bell" />
                  {
                    notifications.length > 0
                    &&
                    <span className="notCount"> {notificationCount} </span>
                  }
                  <div className="copyText">notification</div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="py-0">
                  <div className="notifyDropD">
                    <div className="head">
                      <img src={bell} alt="bell" />
                      notification ({notifications.length})
                    </div>

                        <div className="notRowMain" >
                    {
                      notifications.length > 0 && notifications.map((item, i) => {
                        return (
                            <div className="notRow" key={i}>
                              <div>
                                <Link to={URL_Redirection(item?.msg)} style={{ textDecoration: "none" }}>
                                  <p style={{ color: "#212529" }}> {item?.msg} </p>
                                </Link>
                                <div className="dateTool time">
                                  {moment(item?.timestamp).fromNow()}
                                  <div className="fullDate">{timeConverter(item?.timestamp)}</div>
                                </div>
                                {/* <div className="time">{timeConverter(item.timestamp)} </div> */}
                              </div>
                              <span className="text-danger" style={{ cursor: "pointer" }} onClick={() => seenSingle(item._id, userStore.users.user._id)}> <img className="deleteNoti" src={deleteIco} alt="" /> </span>
                            </div>
                        )
                      })
                    }
                    </div>
                    <div className="jc-sb p-2 ">
                        {
                          showmoreBTN && 
                        <div className="notiBtn mb-2">
                          <button className="btn showBtn"  onClick={() => showmore()}>Showmore</button>
                        </div>
                        }
                        {
                          notifications.length > 0
                          &&
                          <div className="notiBtn">
                            <button className="btn clearBtn "  onClick={() => seenAll()}>Clear all</button>
                          </div>
                        }
                    </div>
                    {/* <div style={{ width: "100%", display: "flex", justifyContent: "space-between", float: "right", padding: "10px 10px" }}>
                    </div> */}

                  </div>

                </Dropdown.Menu>
              </Dropdown>
            </div>

            <div onClick={() => setModalLogout(true)} className="logout mx-2 mx-sm-4 copyLink">
              {/* <Link to="/signin"> */} <img src={power} alt="power" />
              {/* </Link> */}
              <div className="copyText">Logout</div>
            </div>

            {(userStore?.users?.payment?.paymentCategory == "1" ||
              userStore?.users?.payment?.paymentCategory == "2") && (
                <div className="active">
                  {" "}
                  <button>
                    <span></span> active
                  </button>
                </div>
              )}
            {userStore?.users?.payment?.paymentCategory == 0 && (
              <div className="inactive">
                {" "}
                <button>
                  <span></span> in-active
                </button>
              </div>
            )}
          </div>
        </div>
        {!userStore?.users?.user?.permissions.includes(1) && (
          <div
            className="header"
            style={{
              background:
                userStore?.users?.user?.profileBannerPath &&
                  userStore?.users?.user?.profileBannerFormat
                  ? `url(${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${userStore?.users?.user?.profileBannerPath}.${userStore?.users?.user?.profileBannerFormat})`
                  : page == "Billing"? `url(${accessOfGrowing})`: page == "social" ? `url(${learnFromExpert})`:page == "guide" ? `url(${accessOfGrowing})`: `url(${banner})`,
            }}
          >
            <div className="editLabel">
              <label className="">
                <div className="editContainer">
                  <img className="editIcon" src={editIcon} />
                  <input
                    onChange={onBannerFileChange}
                    className="input_file_profilepic"
                    type={"file"}
                  />
                </div>
              </label>
            </div>
          </div>
        )}
      </div>
      <div className="container">
        <div className="leftSideBarBtn" onClick={openLeftSideBar}>
          <i class="fa-solid fa-bars"></i>
        </div>
        {!userStore?.users?.user?.permissions.includes(1) && (
          <div className="profileSec">
            <div className="profileImg">
              {userStore?.users?.user?.profileImagePath &&
                userStore?.users?.user?.profileImageFormat && (
                  <img
                    src={`${process.env.REACT_APP_SERVER_BASE_URL}/uploadedImages/img_${userStore?.users?.user?.profileImagePath}.${userStore?.users?.user?.profileImageFormat}`}
                    alt="profile"
                  />
                )}
              {!userStore?.users?.user?.profileImagePath && (
                <img src={ibtisam} alt="profile" />
              )}
              <label>
                <div className="editContainer">
                  <img className="editIcon" src={editIcon} />
                  <input
                    onChange={onProfileFileChange}
                    className="input_file_profilepic"
                    type={"file"}
                  />
                </div>
              </label>
            </div>
            <div className="name">{userStore?.users?.user?.fullName || userStore?.users?.user?.name}</div>
            <div className={userStore?.users?.payment?.paymentCategory === "0"?
            "designation inactive mt-2"
            :
            "designation mt-2"
             } >
              {userStore?.users?.payment?.paymentCategory === "0" &&
                "You are an Inactive-Member"}
              {userStore?.users?.payment?.paymentCategory === "1" &&
                "You are a Basic Member"}
              {userStore?.users?.payment?.paymentCategory === "2" &&
                "You are a Premium Member"}
            </div>
          </div>
        )}
        {userStore?.users?.user?.profileType == "admin" &&
          userStore?.users?.user?.permissions.includes(1) && (
            <div className="row dash_cont">
              <div className="col-md-3 dash_card">
                <div>
                  <span className="dash_card_heading">Active Users</span>
                  <span className="dash_card_text">
                    {parseInt(basic) + parseInt(premium)}
                  </span>
                </div>
              </div>
              <div className="col-md-3 dash_card">
                <div>
                  <span className="dash_card_heading">In-Active Users</span>
                  <span className="dash_card_text">{inactive}</span>
                </div>
              </div>
              <div className="col-md-3 dash_card">
                <div>
                  <span className="dash_card_heading">Basic Users</span>
                  <span className="dash_card_text">{basic}</span>
                </div>
              </div>
              <div className="col-md-3 dash_card">
                <div>
                  <span className="dash_card_heading">Premium Users</span>
                  <span className="dash_card_text">{premium}</span>
                </div>
              </div>

              <div className="col-md-3 dash_card">
                <div>
                  <span className="dash_card_heading">
                    Total Payment Collected
                  </span>
                  <span className="dash_card_text">{total} $</span>
                </div>
              </div>
              <div className="col-md-3 dash_card">
                <div>
                  <span className="dash_card_heading">
                    Payment Collected - Month
                  </span>
                  <span className="dash_card_text">{totalOfMonth} $</span>
                </div>
              </div>
              <div className="col-md-3 dash_card">
                <div>
                  <span className="dash_card_heading">
                    Payment Collected - Week
                  </span>
                  <span className="dash_card_text">{totalOfWeek} $</span>
                </div>
              </div>
              <div className="col-md-3 dash_card">
                <div>
                  <span className="dash_card_heading">
                    Payment Collected - Day
                  </span>
                  <span className="dash_card_text">{totalOfDay} $</span>
                </div>
              </div>
            </div>
          )}
        <div className="row">
          <div className="col-xl">
            <div className={userStore?.users?.user?.profileType == "admin" ? "sidebarMain  mt-0" : "sidebarMain"} >
              <div className="userNavigationMain" id="leftSideBar">
                <div className="menuText">Menu</div>
                {userStore?.users?.user?.profileType != "admin" && (
                  <NavLink to="/payment" onClick={() => updateNotifications()} className="link col-md">
                    <PaymentIco />
                    Payments
                  </NavLink>
                )}
                {userStore?.users?.user?.profileType != "admin" && (
                  <NavLink to="/Billing" onClick={() => updateNotifications()} className="link col-md">
                    <BillingIco />
                    Billing
                  </NavLink>
                )}
                {userStore?.users?.user?.profileType == "admin" &&
                  (userStore?.users?.user?.permissions?.includes(2) ||
                    userStore?.users?.user?.permissions?.includes(1)) && (
                    <NavLink to="/payment-admin" className="link col-md">
                      <PaymentIco />
                      Payments
                    </NavLink>
                  )}
                {userStore?.users?.user?.profileType == "admin" &&
                  (userStore?.users?.user?.permissions?.includes(3) ||
                    userStore?.users?.user?.permissions?.includes(1)) && (
                    <NavLink to="/admin-billing" className="link col-md">
                      <BillingIco />
                      Billing
                    </NavLink>
                  )}
                {userStore?.users?.user?.profileType != "admin" && (
                  <NavLink to="/social" onClick={() => updateNotifications()} className="link col-md">
                    <SocialIco />
                    Discussions
                  </NavLink>
                )}
                {userStore?.users?.user?.profileType != "admin" && (
                  <NavLink to="/live" onClick={() => updateNotifications()} className="link col-md">
                    <LiveIco />
                    Live
                  </NavLink>
                )}

                <NavLink to="/profile" onClick={() => updateNotifications()} className="link col-md">
                  <ProfileIco />
                  Profile
                </NavLink>
                <NavLink to="/session" onClick={() => updateNotifications()} className="link col-md">
                  <SessionIco />
                  Logged In Devices
                </NavLink>
                {userStore?.users?.user?.profileType == "admin" &&
                  (userStore?.users?.user?.permissions?.includes(4) ||
                    userStore?.users?.user?.permissions?.includes(1)) && (
                    <NavLink to="/manage-users" className="link col-md">
                      <ProfileIco />
                      Users
                    </NavLink>
                  )}
                {userStore?.users?.user?.profileType == "admin" &&
                  userStore?.users?.user?.permissions?.includes(1) && (
                    <NavLink to="/create-admin" className="link col-md">
                      <ProfileIco />
                      Admin
                    </NavLink>
                  )}
                {userStore?.users?.user?.profileType == "admin" &&
                  (userStore?.users?.user?.permissions?.includes(5) ||
                    userStore?.users?.user?.permissions?.includes(1)) && (
                    <NavLink to="/manage-live" className="link col-md">
                      <LiveIco />
                      Live
                    </NavLink>
                  )}

                {userStore?.users?.user?.profileType == "admin" &&
                  userStore?.users?.user?.permissions?.includes(1) && (
                    <NavLink to="/manage-admin" className="link col-md">
                      <ProfileIco />
                      Manage Admin
                    </NavLink>
                  )}

                {(userStore?.users?.user?.permissions.includes(6) ||
                  userStore?.users?.user?.permissions.includes(1)) && (
                    <NavLink to="/signals" onClick={() => updateNotifications()} className="link col-md">
                      <SignalsIco />
                      Signals
                    </NavLink>
                  )}

                {userStore?.users?.user?.profileType != "admin" && (
                  <NavLink to="/signals" className="link col-md">
                    <SignalsIco />
                    Signals
                  </NavLink>
                )}

                {userStore?.users?.user?.profileType != "admin" && (
                  <NavLink to="/crypto-updates" onClick={() => updateNotifications()} className="link col-md">
                    <CryptoIco />
                    <span> Crypto Updates</span>
                  </NavLink>
                )}

                {(userStore?.users?.user?.permissions.includes(7) ||
                  userStore?.users?.user?.permissions.includes(1)) && (
                    <NavLink to="/crypto-updates" className="link col-md">
                      <CryptoIco />
                      Crypto Updates
                    </NavLink>
                  )}

                {userStore?.users?.user?.profileType != "admin" && (
                  <NavLink to="/guides" onClick={() => updateNotifications()} className="link col-md">
                    <GuidesIco />
                    Guides
                  </NavLink>
                )}

                {(userStore?.users?.user?.permissions.includes(8) ||
                  userStore?.users?.user?.permissions.includes(1)) && (
                    <NavLink to="/guides" className="link col-md">
                      <GuidesIco />
                      Guides
                    </NavLink>
                  )}

                {userStore?.users?.user?.profileType != "admin" && (
                  <NavLink to="/issues" className="link col-md">
                    <SupportIco />
                    Website Support
                  </NavLink>
                )}

                {(userStore?.users?.user?.permissions.includes(9) ||
                  userStore?.users?.user?.permissions.includes(1)) && (
                    <NavLink to="/issues" onClick={() => updateNotifications()} className="link col-md">
                      <SupportIco />
                      Website Support
                    </NavLink>
                  )}
                  <a href="https://forum.wealthcoachwaqar.com/" target="_blank" className="link col-md">
                      <SessionIco />
                      Forum
                  </a>

                <div class="closeLeftNav" onClick={closeLeftBar}>
                  <i class="fa-solid fa-arrow-left"></i>
                </div>

              </div>

            </div>
          </div>
          <div className=
            {page == "signals"
              // && 
              // (userStore?.users?.user?.permissions.includes(6) ||
              // userStore?.users?.user?.permissions.includes(1))
              ?
              "col-xl-7"
              : "col-xl-9"
            }
          >
            <div className="navigationCard">

              {page == "payment" &&
                userStore?.users?.user?.profileType != "admin" && (
                  <Payment fetchAdd={fetchAdd} checkSession={props.checkSession} />
                )}
              {page == "social" &&
                userStore?.users?.user?.profileType != "admin" && (
                  <Social checkSession={props.checkSession} />
                )}

              {page == "issues" &&
                userStore?.users?.user?.profileType != "admin" && (
                  <Issues checkSession={props.checkSession} />
                )}

              {page == "profile" && <Profile checkSession={props.checkSession} />}
              {page == "Billing" && <Billing checkSession={props.checkSession} />}
              {page == "session" && <Sessions checkSession={props.checkSession} />}
              {page == "manage-users" &&
                userStore?.users?.user?.profileType == "admin" &&
                (userStore?.users?.user?.permissions?.includes(4) ||
                  userStore?.users?.user?.permissions?.includes(1)) && (
                  <ManageUsers checkSession={props.checkSession} />
                )}
              {page == "admin-billing" &&
                userStore?.users?.user?.profileType == "admin" &&
                (userStore?.users?.user?.permissions?.includes(3) ||
                  userStore?.users?.user?.permissions?.includes(1)) && (
                  <Billing_Admin
                    fetchDashboardUsers={fetchDashboardData}
                    checkSession={props.checkSession}
                  />
                )}
              {page == "payment-admin" &&
                userStore?.users?.user?.profileType == "admin" &&
                (userStore?.users?.user?.permissions?.includes(2) ||
                  userStore?.users?.user?.permissions?.includes(1)) && (
                  <Payment_Admin
                    fetchDashboardUsers={fetchDashboardData}
                    checkSession={props.checkSession}
                  />
                )}
              {page == "manage-live" &&
                userStore?.users?.user?.profileType == "admin" &&
                (userStore?.users?.user?.permissions?.includes(5) ||
                  userStore?.users?.user?.permissions?.includes(1)) && (
                  <AdminLive
                    fetchDashboardUsers={fetchDashboardData}
                    checkSession={props.checkSession}
                  />
                )}
              {page == "create-admin" &&
                userStore?.users?.user?.profileType == "admin" &&
                userStore?.users?.user?.permissions?.includes(1) && (
                  <Admin_Create checkSession={props.checkSession} />
                )}
              {page == "manage-admin" &&
                userStore?.users?.user?.profileType == "admin" &&
                userStore?.users?.user?.permissions?.includes(1) && (
                  <Admin_Manage checkSession={props.checkSession} />
                )}

              {page == "signals" &&
                userStore?.users?.user?.profileType != "admin" && (
                  <Signals checkSession={props.checkSession} />
                )}
              {page == "guides" &&
                userStore?.users?.user?.profileType != "admin" && (
                  <Guides checkSession={props.checkSession} />
                )}
              {page == "crypto-updates" &&
                userStore?.users?.user?.profileType != "admin" && (
                  <CryptoUpdates checkSession={props.checkSession} />
                )}

              {page == "crypto-updates" &&
                (userStore?.users?.user?.permissions.includes(7) ||
                  userStore?.users?.user?.permissions.includes(1)) && (
                  <CryptoUpdates checkSession={props.checkSession} />
                )}
              {page == "signals" &&
                //  (
                //   <Signals checkSession={props.checkSession} />
                // )}
                (userStore?.users?.user?.permissions.includes(6) ||
                  userStore?.users?.user?.permissions.includes(1)) && (
                  <Signals checkSession={props.checkSession} />
                )}

              {page == "guides" &&
                (userStore?.users?.user?.permissions.includes(8) ||
                  userStore?.users?.user?.permissions.includes(1)) && (
                  <Guides checkSession={props.checkSession} />
                )}

              {page == "issues" &&
                (userStore?.users?.user?.permissions.includes(9) ||
                  userStore?.users?.user?.permissions.includes(1)) && (
                  <Issues checkSession={props.checkSession} />
                )}

              {page == "live" && <Live checkSession={props.checkSession} />}
            </div>
          </div>
          {page == "signals" &&
            // (userStore?.users?.user?.permissions.includes(6) ||
            // userStore?.users?.user?.permissions.includes(1))
            // &&  
            <div className="col-xl">
              <div className={userStore?.users?.user?.profileType == "admin" ? "signalCardMain mt-0" : "signalCardMain"} >
                <div className="signalCard">
                  <div className="imgDiv"><img src={investment} alt="" /></div>
                  <h4>Invest</h4>
                  <p>Buy Tenup Now.</p>
                  <div className="button_payment">
                    <a href="https://www.okx.com/trade-spot/tup-usdt" target="_blank" className="w-100 mt-2">Buy</a>
                  </div>
                </div>
                <div className="signalCard">
                  <div className="imgDiv"><img src={passiveIncome} alt="" /></div>
                  <h4>Passive Income</h4>
                  <p>Stake your tenup to earn passive income.</p>
                  <div className="button_payment">
                    <a href="https://dapp.tenup.io/" target="_blank" className="w-100 mt-2">Stake</a>
                  </div>
                </div>
                <div className="signalCard">
                  <div className="imgDiv"><img src={swap} alt="" /></div>
                  <h4>Top Up</h4>
                  <p>Recharge Your Simcard using Tenup</p>
                  <div className="button_payment">
                    <a href="https://topup.tenup.io/" target="_blank" className="w-100 mt-2">Top Up</a>
                  </div>
                </div>

         {/*
                <div className="signalCard news">
                  <h4>Latest News</h4>
                  <p>FTX’s former CEO claims crypto exchange is still solvent</p>
                  <p>New cryptocurrency set to hit 300% more gains than DOGE & SHIB will hit 5.5 million this week</p>
                  <p>Crypto winter is over — and bitcoin could hit $100,000 by the end of 2024, Standard Chartered says</p>
                   <div className="button_payment">
                    <a href="https://dapp.tenup.io/swap" target="_blank" className="w-100 mt-2">Swap</a>
                  </div> 
                </div>
                */}
                
              </div>
            </div>
          }
        </div>
      </div>
      <Loader loader={loader} />
      <Modal
        text={"Are you sure you want to logout?"}
        visible={modalLogout}
        isClose={true}
        iscontinue={true}
        onContinue={() => logout()}
        yesNo={true}
        OnClose={() => setModalLogout(false)}
      />
    </div>
  );
}
