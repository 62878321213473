import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api";
import { Loader } from "./loader";
import moment from "moment"

export function Billing(props) {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const userStore = useSelector((state) => state.userReducer);
  const [isMoreAvailable, setMoreStatus] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    // fetchUserDet();
  }, []);

  useEffect(() => {
    fetchUserDet();
  }, [page]);

  const fetchUserDet = async (uid) => {
    try {
      setLoader(true);
      let response;

      const payload = {
        _id: userStore.users.user._id,
        user: userStore.users.user._id,
        page: page,
      };
      response = await api.getAllPayUser(payload);

      console.warn(response)

      if (response.isRemaining) {
        setMoreStatus(true);
      } else {
        setMoreStatus(false);
      }

      setData([...data, ...response.data]);
      await props.fetchDashboardUsers();
      setLoader(false);
    } catch (e) {
      console.log(e);
      setLoader(false);
    }
  };

  const dateFormat = (datePram) => {
    const date = new Date(datePram);

    // return date.toLocaleString();
    return moment(date).format('MMM DD YYYY, h:mm:ss a')
  };

  const showMore = async () => {
    try {
      setPage(page + 1);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="paymentMain">
      <div className="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">User Email</th>
              <th scope="col">Amount Paid $</th>
              <th scope="col">Date</th>
              <th scope="col">Type</th>
              <th scope="col">Method</th>
              <th scope="col">Paid By</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item) => (
              <>
              <tr>
                <td scope="row">{item?.user?.email}</td>
                <td>{item?.amountPaid} $</td>
                <td>
                  {/* {dateFormat(item?.createdAt)} */}
                  <div className="dateTool">
                    {dateFormat(item?.updatedAt).slice(0,11)}
                    <div className="fullDate">{dateFormat(item?.createdAt)}</div>
                  </div>
                </td>
                <td>
                {item?.type == "1" ? "Basic" : item?.type == "2" ? "Premium" : "N/A" }
                </td>
                <td>
                {item?.paidFrom == "1" ? "Stripe" : item?.paidFrom == "2" ? "Crypto Wallet" : "N/A" }
                </td>
                <td>{item?.from == "0" ? "Paid By Admin" : "Paid By User"}</td>
              </tr>
              <tr class="spacer"><td colspan="100"></td></tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      {isMoreAvailable && <div className="btnShowMoreCont"><button className="showMoreButton" onClick={showMore}>Show more</button></div>}
      {!data.length && (
        <p className="text-center">No data available to show.</p>
      )}

      <Loader loader={loader} />
    </div>
  );
}
